// This file returns an object with the initial state for the warehouse 

const initialState = {
  area: (state) => {
    return ({
      id: "",
      name: "",
      areaType: "area",
      storageReady: false,
      dimensions: { length: 0, width: 0 },
      location: { left: undefined, top: undefined },
      openCeiling: true,
      snapToGrid: false,
      ceilingHeight: { min: 0, max: 0 },
      mountingHeight: { min: 0, max: 0 },
      surroundingWalls: {
        top: false,
        right: false,
        bottom: false,
        left: false,
      },
      areaUsage: "palletRack",
      storageOrientation: "horizontal",
      uprightHeightFt: 16,
      pickingAisles: 0,
      primaryAisles: '',
      connectorAisles: '',
      rackFill: 80,
      aislesArray: [],
      rackArrays: {
        xArray: [],
        yArray: []
      },
      backgroundNoise: 'noisemedium',
      noiseLevel: 65,
      materials: {
        walls: 'CMU_Unpainted',
        ceiling: 'Metal_Deck',
      },
      coverage: {
        picking_aisles: true,
        primary_aisles: false,
        connector_aisles: false,
        general: false,
        none: false,
      },
      systemPrimaryUsage: 'pagingbgm',
      spl: 0,
      sti: 0.6,
      budget: 'Standard',

      speakerAiming: 'aimAuto',
      // marker 
      speakerModel: null,
      /**
       * This is set to false for v1.1.1
       */
      applyDelay: false,
      directAwtdSpl: 0,
      directAwtdSplStd: 0,
      totalAwtdSpl: 0,
      totalAwtdSplStd: 0,
      stiWithNoiseMasking: 0,
      totalPriceMsrp: 0,
      speakerLayout: [],

      speakers: [],
      directAwtdSplLegendMax: 0,
      directAwtdSplLegendMin: 0,
      totalAwtdSplLegendMax: 0,
      totalAwtdSplLegendMin: 0,


      recalculate: true,
      remap: false,

      customized: {
        systemPrimaryUsage: false,
        budget: false,
        qty: false, // this will reset coverage but only UI
        speakerModel: false,
      }
    }
    )
  }
};

export default initialState;