/**
 * @module HeatMapManager
 * @description Manages the rendering of heatmaps for different areas and plot types in the Master Designer application.
 * This component relies on the getPlotData selector for data processing.
 */
import styled from 'styled-components';
import { Fragment } from "react";
import { useSelector } from "react-redux";
import HeatMap from "components/molecules/HeatMap";
import { getDrawingBox } from 'reduxModules/ducks/ui';
import { getPlotData } from 'reduxModules/ducks/container';


/**
 * @function HeatMapManager
 * @description Renders heatmaps for each area based on the current plot data.
 * It uses the getPlotData selector to obtain processed plot information.
 * @returns {React.Component} The rendered HeatMapManager component
 */
const HeatMapManager = () => {
  // Get the drawing box dimensions and scale
  const { bbox, scale, zoom } = useSelector(getDrawingBox);
  const top = bbox.y0; 
  const left = bbox.x0; 

  // Get processed plot data
  const plotData = useSelector(getPlotData);

  return (
    plotData.ready && <Fragment>
      {plotData.areaArray.map((area, index) => {
        // Calculate anchor point for each area
        const anchor = {
          top: top + area.top * scale * !zoom,
          left: left + area.left * scale * !zoom,
          width: area.length * scale ,
          height: area.width * scale ,
        };
        // fixme: temporary stop while areaData is not ready to be renderd 
        if (!area.areaData) return
        return (
          <HeatMap.Wrapper key={area.id} id={area.id} >
            {area.areaData.map((dataSet, index) => {
              const { subdivision: initialSubdivision, data } = dataSet;
              const subdivision = initialSubdivision ?? { type: null, top: 0, left: 0, length: 0, width: 0 };
              const id = data.id;
              return (
                <HeatMap key={id} id={id} anchor={anchor} subdivision={subdivision} data={data} />
              )
            })}
          </HeatMap.Wrapper>
        )
      })}
    </Fragment>
  )
};

/**
 * Styled component for the HeatMap wrapper.
 * @component
 */
HeatMap.Wrapper = styled.div.attrs(props => ({
  className: 'position-absolute flex-grow-1'
}))`
  width: 100%;
  height: 100%;
  top: 0px;
  left: 0px;
  pointer-events: none
`;

export default HeatMapManager;