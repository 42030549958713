import { ceil, floor, mapValues, dropWhile, isEmpty, minBy } from "lodash";

// Bilinear interpolation used to generate heatmaps 
export const bilinearInterpolate = (roomLocation, values, spacing, xOffset, yOffset) => {
  const x = roomLocation.x;
  const y = roomLocation.y;

  const idxX0 = Math.min(Math.max(floor((x - xOffset) / spacing), 0), values[0].length - 1);
  const idxX1 = Math.min(Math.max(ceil((x - xOffset) / spacing), 0), values[0].length - 1);
  const idxY0 = Math.min(Math.max(floor((y - yOffset) / spacing), 0), values.length - 1);
  const idxY1 = Math.min(Math.max(ceil((y - yOffset) / spacing), 0), values.length - 1);

  const x0 = xOffset + idxX0 * spacing;
  const x1 = xOffset + idxX1 * spacing;
  const y0 = yOffset + idxY0 * spacing;
  const y1 = yOffset + idxY1 * spacing;

  const c00 = values[idxY0][idxX0];
  const c10 = values[idxY0][idxX1];
  const c01 = values[idxY1][idxX0];
  const c11 = values[idxY1][idxX1];

  let c0;
  let c1;
  let c;
  if (idxX0 === idxX1) {
    c0 = c00;
    c1 = c01;
  } else {
    c0 = ((x1 - x) * c00 + (x - x0) * c10) / (x1 - x0);
    c1 = ((x1 - x) * c01 + (x - x0) * c11) / (x1 - x0);
  };

  if (idxY0 === idxY1) {
    c = c0;
  } else {
    c = ((y1 - y) * c0 + (y - y0) * c1) / (y1 - y0);
  };
  return c;
};

// Checks wether a value is in range or not, return -1 if it's under, 1 if it's over or 0 when it's in range 
export const inRange = (value, min = null, max = null) => (min && value < min) ? -1 : (max && value > max) ? 1 : 0;

// Check if a matrix contains zero values
const containsZero = (matrix, i1, j1, i2, j2) => {
  const subMatrix = matrix.slice(i1, i2 + 1).map(row => row.slice(j1, j2 + 1));
  return subMatrix.some(row => row.includes(0));
};

// Calculate the sum of a submatrix
const getSubMatrixSum = (prefixSums, i1, j1, i2, j2) => {
  let sum = 0;
  for (let i = i1; i <= i2; i++) {
    sum += prefixSums[i][j2] - (j1 > 0 ? prefixSums[i][j1 - 1] : 0);
  };
  return sum;
};

// Get an area matrix to find the larget area in it 
export const findLargest = (matrix) => {
  const numRows = matrix.length;
  const numCols = matrix[0].length;
  let maxSum = 0;
  let maxSumTopLeft = null;
  let maxSumBottomRight = null;

  // Pre-compute the prefix sum for each row
  const prefixSums = [];
  for (let i = 0; i < numRows; i++) {
    const rowPrefixSums = [matrix[i][0]];
    for (let j = 1; j < numCols; j++) {
      rowPrefixSums.push(rowPrefixSums[j - 1] + matrix[i][j]);
    }
    prefixSums.push(rowPrefixSums);
  };

  // Try all possible sub-matrices
  for (let i1 = 0; i1 < numRows; i1++) {
    for (let j1 = 0; j1 < numCols; j1++) {
      for (let i2 = i1; i2 < numRows; i2++) {
        for (let j2 = j1; j2 < numCols; j2++) {
          if (containsZero(matrix, i1, j1, i2, j2)) { continue }; // Skip sub-matrices that contain a 0
          const sum = getSubMatrixSum(prefixSums, i1, j1, i2, j2);
          if (sum > maxSum) {
            maxSum = sum;
            maxSumTopLeft = [j1, i1];
            maxSumBottomRight = [j2 + 1, i2 + 1];
          };
        };
      };
    };
  };
  return [...maxSumTopLeft || [0, 0], ...maxSumBottomRight || [0, 0]];
};

// get the Y point and tangent along a quadratic Bezier curve 
export const quadraticBezier = (x, [P0, P1, P2] = 'points') => {
  const t = (x - P0.x) / (P2.x - P0.x); // Interpolation parameter
  const y = (1 - t) ** 2 * P0.y + 2 * t * (1 - t) * P1.y + t ** 2 * P2.y;
  const a = mapValues({x:0, y:0}, (v, k) => 2 * (1 - t) * (P1[k] - P0[k]) + 2 * t * (P2[k] - P1[k]));
  return {
    curveY: y,
    angle: Math.atan2(a.y,a.x) * 180 / Math.PI
  };
};

// Get the side of a right triangle
export const side = (c, a) => Math.sqrt(c * c - a * a);

// Return closest number in array to a target value
export const closest = (array, target) => {
  const filteredArray = dropWhile(array, num => num === -1);
  return (isEmpty(filteredArray)) ? 0 : minBy(filteredArray, num => Math.abs(num - target))
};