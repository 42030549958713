/**
 * @module Scale
 * @description Renders a color scale for heatmaps in the Master Designer application.
 */
import styled from 'styled-components';
import { Fragment, useEffect, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import { getColorGradient } from 'reduxModules/ducks/ui';
import Label from 'components/atoms/Label';
import Canvas from 'components/atoms/Canvas';
import Button from 'components/molecules/Button';
import Info from 'components/atoms/Info';
import { getArea, getPlotProps } from 'reduxModules/ducks/container';
import { toString, isEmpty, toPairs, round } from 'lodash';

/**
 * @function range2value
 * @description Converts a pixel position to a value in the plot range.
 * @param {Object} plotProps - The plot properties
 * @param {number} canvasWidth - The width of the canvas
 * @param {number} x - The x-coordinate on the canvas
 * @returns {number} The corresponding value in the plot range
 */
const range2value = (plotProps, canvasWidth, x) => {
  const { minLevel, maxLevel, accuracy } = plotProps;
  return round(((maxLevel - minLevel) / canvasWidth * x + minLevel) * accuracy);
};

/**
 * @function value2range
 * @description Converts a value in the plot range to a pixel position on the canvas.
 * @param {Object} plotProps - The plot properties
 * @param {number} canvasWidth - The width of the canvas
 * @param {number} level - The value in the plot range
 * @returns {number} The corresponding x-coordinate on the canvas
 */
const value2range = (plotProps, canvasWidth, level) => {
  const { minLevel, maxLevel } = plotProps;
  return ((level - minLevel) * (canvasWidth / (maxLevel - minLevel)));
};

/**
 * @function getLabels
 * @description Generates labels for the scale based on plot properties.
 * @param {Object} plotProps - The plot properties
 * @returns {Object} An object with label values as keys and corresponding plot values
 */
const getLabels = (plotProps) => {
  const { labels = {}, minLevel, maxLevel } = plotProps
  if (isEmpty(labels)) {
    const stepSize = (maxLevel - minLevel) / 6
    for (let i = minLevel + stepSize; i < maxLevel; i += stepSize) {
      labels[toString(i)] = i;
    };
  };
  return labels
};

/**
 * @function Scale
 * @description Renders the color scale component with labels and additional information.
 * @returns {React.Component} The rendered Scale component
 */
const Scale = () => {
  const plotProps = useSelector(getPlotProps);
  const inArea = useSelector(getArea);

  const gradientRGBA = useSelector(getColorGradient) ?? {};

  /**
   * @description Effect to draw the color scale on the canvas
   */
  const canvasRef = useRef();
  useEffect(() => {
    if (canvasRef.current) {
      const canvasWidth = canvasRef.current.offsetWidth;
      const ctx = canvasRef.current.getContext('2d');
      ctx.canvas.width = canvasWidth;
      ctx.canvas.height = 20;
      ctx.clearRect(0, 0, canvasWidth, 20);
      for (var x = 0; x <= canvasWidth; x++) {
        ctx.strokeStyle = `rgba(${gradientRGBA[range2value(plotProps, canvasWidth, x)]})`
        ctx.beginPath();
        ctx.moveTo(x, 0);
        ctx.lineTo(x, 20);
        ctx.stroke();
      };
      // Labels
      ctx.fillStyle = "rgba(45, 47, 56 , .8)";
      ctx.font = "16px Gotham-book";
      ctx.textAlign = "center";
      ctx.textBaseline = "middle";
      const labels = getLabels(plotProps);
      toPairs(labels).forEach(([key, value]) =>
        ctx.fillText(key, value2range(plotProps, canvasWidth, value), 11));
    };
  });

  const theme = {
    labelWidth: 'auto',
    labelAlign: 'start',
    overflow: 'visible',
  };

  const [isOpen, setIsOpen] = useState(false);
  const onMouseDown = () => {
    setIsOpen(true)
  };

  const onMouseUp = () => {
    setIsOpen(false)
  };

  const { label, units, info, average, deviation } = plotProps;
  const showAverage = inArea && average;

  return (
    <Scale.Wrapper >
      {label && <Label className='text-nowrap' theme={theme} htmlFor={''} type={'text'}>{label}</Label>}
      <Canvas id='scale' ref={canvasRef} legend={true} />
      {units && <Label className='text-nowrap ms-1' theme={theme} >{units}</Label>}
      {info && <Button name={'help'} state={'button_active'}
        place={'top'} onMouseDown={onMouseDown} onMouseUp={onMouseUp} />}
      <Info id={'help'} isOpen={isOpen} />
      {showAverage && <Fragment>
        <div className="vr mx-2"></div>
        <Label className='text-nowrap 1' theme={theme} >AVG: {average}</Label>
        <Label className='text-nowrap 2' theme={theme} >&nbsp;&plusmn; {deviation}</Label>
        <Label className='text-nowrap 3' theme={theme} >&nbsp;{units}</Label>
      </Fragment>}
    </Scale.Wrapper>
  )
};

/**
 * Styled component for the Scale wrapper.
 * @component
 */
Scale.Wrapper = styled.div.attrs(props => ({
  className: 'hstack mx-auto '
}))`
  width: 95%;
  padding-bottom: 4px;
  max-height: 34px
`;

export default Scale;
