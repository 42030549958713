/**
 * @module errorMiddleware
 * @description Middleware for handling errors and validations in the Conference Room Designer application.
 */

import ReducersIndex from "reduxModules/ducks";
import { getAreaProperty, getCurrentValue2, getArea, getRemap } from "reduxModules/ducks/container";
import { getStoreProp } from "utilities/string";
import toolIndex from "toolConstants";
import { some, lowerCase } from "lodash";

/**
 * @function errorMiddleware
 * @description Intercepts Redux actions to handle errors, perform validations, and manage customizations.
 * @param {Object} store - The Redux store.
 * @returns {Function} A middleware function.
 */
const errorMiddleware = ({ dispatch, getState }) => (next) => (action) => {
  /**
   * @section Pre action items
   * @description All the logic below intercepts the action to prevent somethoing from happening 
   */
  let store = getState();
  const tool = store.container.currentTool;

  switch (true) {
    case action.type === ReducersIndex.uiReducers.closeModal.type:
      if (['resetCustomization'].includes(action.payload) && store.ui.errorState) {
        dispatch(ReducersIndex.uiReducers.closeWarning())
      }

    // intentional fall through
    case action.type === ReducersIndex.uiReducers.openModal.type:
      if (!['warning', 'removeDock', 'resetCustomization'].includes(action.payload) && store.ui.errorState ) { // && !store.ui.exitToRoom
        dispatch(ReducersIndex.uiReducers.openModal('warning'));
        return
      };
      break;

    /**
     * @description conditions to check before the axction is executed and values changes in the store
     */
    case action.type === ReducersIndex.container.deleteSpeaker.type:
      const speakerCount = getCurrentValue2(store, 'speakers').length;
      if (speakerCount <= 1) {
        const error = {
          code: 110,
          msg: `Can't delete the last loudspeaker in the area.\nTo remove all loudspeakers, set the area coverage to "None"`,
          reset: true,
        };
        dispatch(ReducersIndex.uiReducers.setError(error));
        return
      };
      break;

    case action.type === ReducersIndex.container.addDevice.type:
      const currentDevice = getCurrentValue2(store, 'currentDevice');
      const deviceCount = getCurrentValue2(store, `${currentDevice}.qty`);
      const maximumCount = toolIndex[tool].constants?.tool?.maximumDevices?.[currentDevice] ?? 1;
      if ( deviceCount >= maximumCount ) {
        const error = {
          code: 120,
          msg: `Can't add more ${currentDevice}.`,
          reset: true
        };
        dispatch(ReducersIndex.uiReducers.setError(error));
        return
      }
      break;

    /**
     * @description This last action is a general stopper to reset customization
     * The "localCalc" parameters filters actions so only Warehousse Designer actions go thru here
     * i.e. Both Conference Room Desinger and Classroom Designer don't use this functionality
     * 
     * @note Since this catches any and all "container" actions, it needs to run last 
     * otherwise the previous actions would not run
     */
    case action.type.includes('container'):
      const customized = getAreaProperty(store, 'customized');
      const currentArea = getArea(store);
      const remap = getRemap(store);
      const tool = store.container.currentTool;
      const localCalc = toolIndex[tool].constants?.tool?.localCalc ?? false;

      if (currentArea && some(customized) && !localCalc ) {
        const customReseters = toolIndex[tool].constants.customReseters ?? [];
        const parameter = getStoreProp(action.type);
        const device = getCurrentValue2(store, 'currentDevice');

        if (customReseters.includes(parameter)) {
          const payload = {
            area: currentArea,
            update: {
              parameter: parameter,
              value: action.payload
            },
          };
          dispatch(ReducersIndex.container.setAreaToUpdate(payload));

          // set and open the modal
          const msg = (parameter === 'coverage' && action.payload.none) ? 'clear all customizations.' : `reset ${device} to the recommended solution.`
          const error = {
            code: 100,
            msg: `Changing ${lowerCase(parameter)} will ${msg}`,
            reset: true,
          };
          // timer is to prevent recalculating before redraw is done 
          setTimeout(() => dispatch(ReducersIndex.uiReducers.setError(error)), remap ? 1500 : 50);
          // prevent further action
          return;
        };
      };
      break



    default:
      break;
  }

  /**
   * @description run the actions if they have not stopped 
   */
  next(action);
  store = getState();

  /**
   * @section Post action items
   * @description All the logic below happens after the action. Errors will be highlighted and indicated to the user
   * but the user needs to correct them as the value has already been passed to the store 
   */

  /**
   * General Validation
   * @description This checks what parameter in the store is changing.
   * If validation has a function with the same name, it executes that validation 
   */
  const validate = toolIndex[tool]?.validate;
  switch (true) {
    case action.type.includes('container'):
      const parameter = getStoreProp(action.type);
      if (parameter in validate) {
        validate[parameter](dispatch, store)
      };
      break

    case action.type === ReducersIndex.uiReducers.setOverlaping.type:
      validate.overlaping(dispatch, store);
      break;

    /**
     * @description Once all validation has gone thru. if any errors are set, the function 
     * below opens the apropiate modal 
     */
    case action.type === ReducersIndex.uiReducers.setError.type:
      // note: this triger should look for last trigger 

      const inError = store.ui.errorState;
      const inMenu = store.ui.exitToRoom;
      const error = action.payload//.msg;
      // note hasMsg is almost as good or better than errorstate
      const test = inError && inMenu && error.msg

      if (error.msg) console.log("show error: ", test, ' - ', inError, inMenu, error.msg);
      // console.log("error.code: ", error.code, error.code === 100);
      // dispatch(ReducersIndex.uiReducers.openModal('resetCustomization'))
      if (test) dispatch(ReducersIndex.uiReducers.openModal((error.code === 100 ? 'resetCustomization' : 'warning')));
      break;


    default:

      break;
  }



};

export default errorMiddleware;
