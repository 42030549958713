import styled, { ThemeProvider } from 'styled-components';
import { Fragment, useContext, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Draggable from 'react-draggable';
import Fade from 'components/services/Fade';
import { Error, Info } from 'components/atoms/PlainText';
import { ComponentsIndex } from 'globalConstants/ComponentIndex';
import ReducersIndex from 'reduxModules/ducks';
import { modalThemes } from 'globalConstants/Themes';
import ToolContext from 'components/services/ToolContext';
import { getErrorState } from 'reduxModules/ducks/ui';
import { getConditionResult, getCurrentValue, getCurrentValue2 } from "reduxModules/ducks/container";
import { some, merge, find } from 'lodash';

const Modal = ({ modalName, top = 0 }) => {
  const toolContext = useContext(ToolContext);
  let {dialog, ...modalContent} = toolContext.modals[modalName];
  if (dialog) {
    modalContent = merge({}, toolContext.modals[dialog], modalContent); 
  };

  const dispatch = useDispatch();
  const currentView = useSelector(state => state.ui.currentView);
  const exitToRoom = useSelector(state => state.ui.exitToRoom);
  const inError = useSelector(getErrorState);
  const firstTab = useSelector(state => state.ui.tabs[0].id);
  // const selfResetting = useSelector(state => find(getCurrentValue2(state, 'error'), err => err.reset))
  //const selfResetting = find(getCurrentValue2(store, 'error'), err => err.reset);

  const close = (evt, modal = null, dispatcher = null) => {
    // console.log("modal: ", modalName, '--',modal, '--', dispatcher);
    dispatch(ReducersIndex.uiReducers.closeModal(modalName));
    if (dispatcher) {
      // console.log('one');
      if (dispatcher.name in ReducersIndex.container) dispatch(ReducersIndex.container[dispatcher.name](dispatcher.value));
      if (dispatcher.name in ReducersIndex.uiReducers) dispatch(ReducersIndex.uiReducers[dispatcher.name](dispatcher.value));
    };
    // console.log("inError: ", inError);
    if (modal || inError) {
      // console.log('two ', selfResetting);
      
      if (modal in toolContext.modals) dispatch(ReducersIndex.uiReducers.openModal(modal));
      // if (selfResetting) dispatch(ReducersIndex.uiReducers.setError({code: selfResetting.code}))

    } else {
      dispatch(ReducersIndex.uiReducers.setCurrentView(exitToRoom ? currentView : toolContext.constants.tool.landingMenu));
      if (!exitToRoom) dispatch(ReducersIndex.uiReducers.setActiveTab(firstTab));
      dispatch(ReducersIndex.uiReducers.setExitToRoom(true));
      dispatch(ReducersIndex.uiReducers.clearModals());
    };
  };

  const useTheme = modalThemes[modalContent?.theme || 'default'];
  const show = useSelector(state => state.ui.modals.find(modal => modal.modalName === modalName).modalOpen)
  const nodeRefOut = useRef(null);
  const nodeRefIn = useRef(null);
  return (
    <Fade duration={'slow'} show={show} name={modalName}>
      <ThemeProvider theme={useTheme}>
        <Modal.Background image={modalContent.image}>
          <Modal.Wrapper bg={modalContent.image == null} top={top}>
            <Draggable nodeRef={nodeRefOut} bounds="parent" handle={'.modalHeader'} position={show ? undefined : { x: 0, y: 0 }}>
              <Modal.Dialog ref={nodeRefOut}>
                <Draggable nodeRef={nodeRefIn} bounds="parent" handle={'.modalHeader'} position={show ? undefined : { x: 0, y: 0 }}>
                  <Modal.Content ref={nodeRefIn} >
                    <Modal.Header content={modalContent.header} />
                    <Modal.Body show={show} lines={modalContent.body} dispatcher={modalContent.dispatcher} />
                    <Modal.Footer buttons={modalContent.footer} closeModal={close} />
                  </Modal.Content>
                </Draggable>
              </Modal.Dialog>
            </Draggable>
          </Modal.Wrapper>
        </Modal.Background>
      </ThemeProvider>
    </Fade>
  );
};

Modal.Background = styled.div.attrs(props => ({
  className: 'container-medium',
}))`
background-image: url(${props => props.image});
background-size: cover;
z-index: 1;
`;

Modal.Wrapper = styled.div.attrs(props => ({
  className: 'modal wrappermod',
  tabIndex: "-1",
  'data-bs-backdrop': 'static',
  'data-bs-keyboard': 'false'
}))`
  background: ${props => props.bg && 'rgba(255, 255, 255, 0.5)'};
  display: block;
  margin-top: ${props => `${props.top}px`};
  height: ${props => `calc(100% - ${props.top}px - 30px)`};
`;

Modal.Dialog = styled.div.attrs(props => ({
  className: 'modal-dialog modal-dialog-centered modal-lg',
}))`
`;

Modal.Content = styled.div.attrs(props => ({
  className: 'modal-content',
}))`
  position: absolute;
  top: calc(15% + 30px);
  background-color: ${props => props.theme.background};
  border-radius: 0;
  opacity: ${props => props.theme.opacity};
  color: ${props => props.theme.color};
  padding: ${props => props.theme.padding};
`;

// Header Stuff
Modal.Header = ({ content }) => {
  const Element = ComponentsIndex['plainText'];
  return (
    <Modal.Header.HeaderWrapper>
      <Element type={'h4'} bold={true} content={content || "Oops..."} />
    </Modal.Header.HeaderWrapper>)
};

Modal.Header.HeaderWrapper = styled.div.attrs(props => ({
  className: 'modalHeader',
}))`
  background-color: ${props => props.theme.headerBackground || props.theme.background};
  color: ${props => props.theme.headerColor || props.theme.color};
  padding: ${props => props.theme.headerPadding};
  text-align: ${props => props.theme.headerAlign || 'start'};
  border-color: ${props => props.theme.headerBorder};
  border-width: ${props => props.theme.headerBorder ? '2px' : '0px'};
  border-style: ${props => `none none ${props.theme.headerBorder ? 'solid' : 'none'} none`};
  &:hover {
    cursor: move
  }
`;

// Body Stuff
Modal.Body = (props) => {
  return (
    <Modal.Body.BodyWrapper>
      {props.lines ? props.lines.map((line, index) => {
        const lineProps = {
          ...line,
          dispatcher: line.dispatcher || props.dispatcher,
        };
        return <Line key={`${line.name}-${index}`} {...lineProps} show={props.show}/>
      }) : <div>This modal has not been created yet</div>
      }
    </Modal.Body.BodyWrapper>
  )
};

Modal.Body.BodyWrapper = styled.div.attrs(props => ({
  className: 'modalBody vstack ',
}))`
  padding: ${props => props.theme.bodyPadding};
`;

// new code
const Line = (props) => {
  const visible = useSelector(state => getConditionResult(state, {...props.condition}));
  const Element = ComponentsIndex[props.element];
  const error = useSelector(state => getCurrentValue2(state, 'error')[props.errorCode]?.msg);
  const info = useSelector(state => getCurrentValue2(state, 'info')[props.infoCode]?.msg);
  const extend = props.extend || props.errorCode !== undefined || props.infoCode !== undefined;
  return (
    <Line.LineWrapper className={props.className} animate={props.show} visible={visible} extend={extend}>
      <Element {...props} show={props.show} dispatcher={props.dispatcher} />
      <div className='mb-2' ></div>
      {error && <Error msg={error} />}
      {info && <Info msg={info} />}
    </Line.LineWrapper>
  )
};

Line.LineWrapper = styled.div.attrs(props => ({
  className: `modalLine ${props.className}`
}))`
  overflow: hidden;
  max-height: ${props => props.visible ? props.extend ? 'fit-content' : '50px' : '0px'};
  transition: ${props => props.animate ? 'max-height .6s ease-out' : 'none'};
`;

// Footer stuff
Modal.Footer = (props) => {
  const buttons = props?.buttons || [{ label: 'Close' }];
  const Element = ComponentsIndex['button'];
  const center = buttons.length <= 1 || some(buttons, {'label': 'CANCEL'}) 
  return (
    <Modal.Footer.FooterWrapper center={center}>
      {buttons?.reduce((acc, button, index) => {
        const key = useSelector(state => getConditionResult(state, {...button?.condition}));
        if (button.condition) {
          button = {
            ...button,
            label: button.content[key]?.label,
            target: button.content[key]?.target,
            dispatcher: button.content[key]?.dispatcher
          };
        };
        const theme = { background: 'transparent', color: button.color, width: center ? '18%' : '' };
        const onClick = button.label ? (e) => props.closeModal(e, button.target, button.dispatcher) : () => null;
        acc.push(<Element key={`btn-${index}`} className={`btn-${index}`} theme={theme} label={button.label} onClick={onClick} />)
        return acc
      }, [])}
    </Modal.Footer.FooterWrapper>
  );
};

Modal.Footer.FooterWrapper = styled.div.attrs(props => ({
  className: `modal-footer mt-4 py-2 text-center h-stack d-flex justify-content-${props.center ? 'center' : 'between'}`,
}))`
  background-color: ${props => props.theme.footerBackground};
  margin-top: ${props => props.theme.footerMargin};
  padding: ${props => props.theme.bodyPadding};
  color: var(--app-red);
  height: ${props => props.center ? '46px' : 'auto'}
`;



export default Modal;

