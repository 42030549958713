/**
 * @module HeatMap
 * @description Renders a heatmap visualization on a canvas element for the Master Designer application.
 */
import { useEffect, useRef } from 'react';
import { useSelector } from "react-redux";
import Canvas from 'components/atoms/Canvas';
import { getColorGradient, getScale } from 'reduxModules/ducks/ui';
import { inRange, isNull, round } from 'lodash';
import { getPlotProps } from 'reduxModules/ducks/container';

/**
 * @function HeatMap
 * @description Renders a heatmap based on provided data and plot properties.
 * 
 * @param {Object} props - The component props
 * @param {string} props.id - Unique identifier for the heatmap
 * @param {Object} props.anchor - Position and dimensions of the heatmap anchor
 * @param {Object} props.subdivision - subdivision information for positioning the heatmap
 * @param {Object} props.data - Data for generating the heatmap
 * @param {number[][]} props.data.mapArray - 2D array of heatmap values
 * @param {number} props.data.receiverSpacing - Spacing between data points
 * @param {number} props.data.receiverXOffset - X-axis offset for data points
 * @param {number} props.data.receiverYOffset - Y-axis offset for data points
 * 
 * @returns {React.Component} The rendered HeatMap component
 */
const HeatMap = ({ id, anchor, subdivision, data }) => {
  const scale = useSelector(getScale);
  const { minLevel, maxLevel, accuracy, pointToPixelValue } = useSelector(getPlotProps);
  const gradientRGBA = useSelector(getColorGradient);

  const canvasRef = useRef();

  /**
   * @description Effect to draw the heatmap on the canvas
   */  
  useEffect(() => {
    if (canvasRef.current) {
      const canvasWidth = canvasRef.current.offsetWidth;
      const canvasHeight = canvasRef.current.offsetHeight;
      const ctx = canvasRef.current.getContext('2d');
      const heatmapimage = ctx.createImageData(canvasWidth, canvasHeight);
      const heatmapdata = heatmapimage.data;
      const plot = Array(canvasWidth * canvasHeight).fill(0);
      if (data.mapArray) {
        for (var i = 0; i < plot.length; i++) {
          const y = Math.floor(i / canvasWidth);
          const x = i - y * canvasWidth;
          const dx = x / scale + subdivision.left;
          const dy = y / scale + subdivision.top;
          let pixelLevel = pointToPixelValue({ x: dx, y: dy }, data)
          pixelLevel = inRange(pixelLevel, minLevel) ? minLevel : !inRange(pixelLevel, maxLevel) ? maxLevel : pixelLevel;
          const baseindex = 4 * i;
          const rgb = gradientRGBA[round(pixelLevel * accuracy)];
          if (!rgb || rgb.length < 3) continue;
          heatmapdata[baseindex] = rgb[0]; //red
          heatmapdata[baseindex + 1] = rgb[1]; //green
          heatmapdata[baseindex + 2] = rgb[2]; //blue
          heatmapdata[baseindex + 3] = (rgb[3] ?? 1) * 255; //alpha
        };
      };
      ctx.putImageData(heatmapimage, 0, 0);
    };
    // eslint-disable-next-line
  }, [anchor, minLevel, maxLevel, accuracy])

  /**
   * @description Calculate canvas properties based on anchor and subdivision
   */  
  const props = {
    id: id,
    top: anchor.top + subdivision.top * scale + 1.5 * +!isNull(subdivision.type),
    left: anchor.left + subdivision.left * scale + 1.5 * +!isNull(subdivision.type),
    width: (subdivision.length * scale) || anchor.width,
    height: (subdivision.width * scale) || anchor.height
  }

  return (
    <Canvas ref={canvasRef} {...props}></Canvas>
  )
};

export default HeatMap