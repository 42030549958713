
/**
 * @module devicePlots
 * @description Defines plot configurations for different device types in the Master Designer application.
 * These configurations are used to render heatmaps and other visualizations.
 */
import { bilinearInterpolate } from "utilities/mathematic";

/**
 * @typedef {Object} PlotConfig
 * @property {string} label - The display label for the plot.
 * @property {number|string} minLevel - The minimum value for the plot legend. Can be a fixed number or a key referencing a value in the Redux store.
 * @property {number|string} maxLevel - The maximum value for the plot legend. Can be a fixed number or a key referencing a value in the Redux store.
 * @property {string} source - The key for the data source matrix in the Redux store.
 * @property {number} accuracy - The precision factor for calculations (e.g., 10 for one decimal place).
 * @property {string} [units] - The units of measurement for the plot values.
 * @property {string} average - The key for the average value in the Redux store.
 * @property {string} deviation - The key for the standard deviation in the Redux store.
 * @property {Function} pointToPixelValue - Takes the pixel coordinates and returns the value required to set color in the plot
 * @property {number} [roundTo] - The number of decimal places to round the values to.
 * @property {Object} [labels] - Custom labels for specific value ranges, used in plots like STI.
 */

/**
 * @typedef {Object} DeviceConfig
 * @property {string|null} default - The key of the default plot to display for this device type.
 * @property {Object.<string, PlotConfig>} plots - A collection of plot configurations for this device type.
 */

/**
 * Configuration object for device-specific plots.
 * @type {Object.<string, DeviceConfig>}
 * @description Defines plot configurations for different device types (microphones, speakers, etc.).
 * Each device type has a default plot and a collection of available plots.
 * Some values reference properties in the Redux store's container state.
 * 
 */
const devicePlots = {
  microphones: {
    default: 'micDistance',
    plots: {
      micDistance: {
        label: 'Distance from nearest mic',
        plotType: 'micDistance',
        minLevel: 0,
        maxLevel: 'microphones.micDistanceLegendMax',
        target: 'microphones.micMaxRadius',
        accuracy: 100,
        pointToPixelValue: (point, data) => {
          return data.mapArray.reduce((minDist, mic) => {
            const dx = mic.location.x - point.x;
            const dy = mic.location.y - point.y;
            const dist = Math.hypot(dx, dy);
            return Math.min(minDist, dist)
          }, Infinity)
        }
      }
    }
  },
  speakers: {
    default: 'directSPL',
    plots: {
      directSPL: {
        label: 'Direct SPL',
        plotType: 'SPL',
        minLevel: 'directAwtdSplLegendMin',
        maxLevel: 'directAwtdSplLegendMax',
        source: 'directAwtdSplMatrix',
        accuracy: 10,
        units: 'dBA',
        average: 'directAwtdSpl',
        deviation: 'directAwtdSplStd',
        pointToPixelValue: (point, data) => {
          const { mapArray, receiverSpacing, receiverXOffset, receiverYOffset } = data;
          return bilinearInterpolate(point, mapArray, receiverSpacing, receiverXOffset, receiverYOffset)
        }
      },
      totalSPL: {
        label: 'Total SPL',
        plotType: 'SPL',
        minLevel: 'totalAwtdSplLegendMin',
        maxLevel: 'totalAwtdSplLegendMax',
        source: 'totalAwtdSplMatrix',
        accuracy: 10,
        units: 'dBA',
        average: 'totalAwtdSpl',
        deviation: 'totalAwtdSplStd',
        pointToPixelValue: (point, data) => {
          const { mapArray, receiverSpacing, receiverXOffset, receiverYOffset } = data;
          return bilinearInterpolate(point, mapArray, receiverSpacing, receiverXOffset, receiverYOffset)
        }
      },
      STI: {
        label: 'Estimated STI',
        plotType: 'STI',
        minLevel: 0,
        maxLevel: 1,
        source: 'stiWithNoiseMaskingMatrix',
        accuracy: 1000,
        roundTo: 2,
        labels: {
          BAD: .15,
          POOR: .375,
          FAIR: .525,
          GOOD: .675,
          EXCELLENT: .875
        },
        average: 'stiWithNoiseMasking',
        deviation: 'stiWithNoiseMaskingStd',
        pointToPixelValue: (point, data) => {
          const { mapArray, receiverSpacing, receiverXOffset, receiverYOffset } = data;
          return bilinearInterpolate(point, mapArray, receiverSpacing, receiverXOffset, receiverYOffset)
        },
        info: 'placeholder for info button - replace by a link to the content that goes here'
      }
    }
  },
  // Temporary place holder to prevent runtime errors
  camera: {
    default: 'none',
    plots: {
      none: {}
    }
  }
}

export default devicePlots;