import modals from "toolConstants/warehouseDesigner/modals";
import constants from "toolConstants/warehouseDesigner/constants";
import { some, pick, omit, keys } from "lodash";

const menus = {
  floor_plan: {
    container: [...modals.warehouse.body.slice(1, -2)],
    area: [...modals.area.body.slice(1, -1)],
  },

  roof: {
    container: [...modals.roof.body.slice(0, -1)].map(element => ({ ...element, 'className': element.className?.replace('2', '0') })),
  },

  area_usage: {
    area: [
      ...modals.areaUsage.body.slice(0, -1)].map(element => ({ ...element, 'label': element.label.replace('Number of', ''), 'bubbles': element.tooltips }))
  },

  acoustics: {
    container: [
      {
        element: 'groupLabel',
        label: 'Surface Materials'
      },
      {
        element: 'selector',
        className: 'ms-2',
        name: 'walls',
        label: 'Walls',
        placeHolder: 'Select material',
        items: ['CMU_Unpainted', 'CMU_Painted', 'Drywall', 'Steel_Siding', 'Insulation Batt'],
        dispatcher: 'setMaterials'
      },
      {
        element: 'selector',
        className: 'ms-2',
        name: 'ceiling',
        label: 'Ceiling',
        placeHolder: 'Select material',
        items: ['Metal_Deck', 'Concrete_Panel', 'Insulation Batt', 'Drywall'],
        dispatcher: 'setMaterials'
      },
    ],
    area: [
      ...modals.areaDesignPriority.body.slice(0, 2),
      {
        condition: { parameter: ['surroundingWalls', 'openCeiling'], check: ([walls, open]) => some(walls) || !open },
        element: 'groupLabel',
        label: 'Surface Materials'
      },
      {
        condition: { parameter: ['surroundingWalls'], check: ([walls]) => some(walls) },
        element: 'selector',
        className: 'ms-2',
        name: 'walls',
        label: 'Walls',
        placeHolder: 'Select material',
        items: ['CMU_Unpainted', 'CMU_Painted', 'Drywall', 'Steel_Siding', 'Insulation Batt'],
        dispatcher: 'setMaterials'
      },
      {
        condition: { parameter: ['openCeiling'], check: ([open]) => !open },
        element: 'selector',
        className: 'ms-2',
        name: 'ceiling',
        label: 'Ceiling',
        placeHolder: 'Select material',
        items: ['Metal_Deck', 'Concrete_Panel', 'Insulation Batt', 'Drywall'],
        dispatcher: 'setMaterials'
      },
      {
        condition: { parameter: ['coverage', 'areaUsage'], check: ([coverage, usage]) => !coverage.none && usage === 'palletRack' },
        element: 'measurementInput',
        unit: '%',
        spinStep: 20,
        range: { min: 40, max: 100 },
        label: 'Rack Fill',
        dispatcher: 'setRackFill'
      },
    ],
  },

  design_priority: {
    area: [
      {
        element: 'toggleSelect',
        // className: 'd-table',
        label: 'Coverage',
        labels: false,
        items: {
          palletRack: ['picking_aisles', 'primary_aisles', 'general', 'none'], // 'connector_aisles',
          automatedHandling: ['general', 'none'],
          manualHandling: ['general', 'none'],
          pallet: ['general', 'none'],
          trafficArea: ['general', 'none']
        },
        keyCondition: { parameter: ['areaUsage'], check: ([usage]) => usage },
        bubbles: {
          picking_aisles: 'Picking Aisles',
          primary_aisles: 'Primary Aisles',
          general: 'General',
          none: 'None'
        },
        toggle: ['picking_aisles', 'primary_aisles', 'general', 'none'],
        noSelect: 'none',
        theme: 'buttonGroupBigNav',
        activeState: 'coverage_active',
        inactiveState: "coverage_inactive",
        dispatcher: 'setCoverage',
        activeCondition: { parameter: ['customized'], check: ([customized]) => customized.qty }
      },
      {
        condition: { parameter: ['showing', 'coverage'], check: ([showing, coverage]) => showing === 'area' && !coverage.none },
        element: 'toggleButtons',
        label: 'System Primary Usage',
        items: ['paging_bgm', 'paging'],
        bubbles: {
          paging_bgm: 'Paging and Background Music',
          paging: 'Paging Only'
        },
        dispatcher: 'setSystemPrimaryUsage'
      },
      ...modals.areaDesignPriority.body.slice(4, -2),
      {
        condition: { parameter: ['coverage'], check: ([coverage]) => !coverage.none },
        element: 'selector',
        label: 'Budget',
        placeHolder: 'Select Budget',
        items: ['Value', 'Standard', 'Premium'],
        dispatcher: 'setBudget'
      },
      // new code
      {
        condition: { parameter: ['coverage', 'areaUsage'], check: ([coverage, usage]) => usage === 'palletRack' ? coverage.general : some(omit(coverage, ['none'])) },
        element: 'toggleButtons',
        label: 'Speaker Aiming',
        items: ['aimAuto', 'aimHorizontalOut', 'aimVerticalOut', 'aimLeft', 'aimBottom', 'aimRight', 'aimTop'],
        dispatcher: 'setSpeakerAiming',
      },
      {
        condition: {
          parameter: ['coverage', 'areaUsage', 'storageOrientation'], check: ([coverage, usage, orientation]) =>
            usage === 'palletRack' && some(pick(coverage, orientation === 'horizontal' ? ['picking_aisles', 'connector_aisles'] : ['primary_aisles']))},
        element: 'toggleButtons',
        label: 'Speaker Aiming',
        items: ['aimAuto', 'aimHorizontalOut', 'aimLeft', 'aimRight'],
        dispatcher: 'setSpeakerAiming',
      },
      {
        condition: {
          parameter: ['coverage', 'areaUsage', 'storageOrientation'], check: ([coverage, usage, orientation]) =>
            usage === 'palletRack' && some(pick(coverage, orientation === 'vertical' ? ['picking_aisles', 'connector_aisles'] : ['primary_aisles']))},
        element: 'toggleButtons',
        label: 'Speaker Aiming',
        items: ['aimAuto', 'aimVerticalOut', 'aimBottom', 'aimTop'],
        dispatcher: 'setSpeakerAiming',
      },
      {
        condition: { parameter: ['coverage'], check: ([coverage]) => !coverage.none },
        element: 'selector',
        label: 'Speaker Model',
        items: keys(constants.equipment),
        dispatcher: 'setSpeakerModel'
      },
      // {
      //   element: 'slider',
      //   label: 'Apply Delay',
      //   dispatcher: 'setApplyDelay'
      // },
    ],
  },
  speakers: {
    container: [
      {
        element: 'groupLabel',
        label: 'Render'
      },
      {
        element: 'checkBox',
        className: 'ms-2',
        name: 'heatmap',
        items: ['total_SPL', 'direct_SPL', 'STI'],
        dispatcher: 'setShowMap'
      },
    ],
    area: [
      {
        element: 'groupLabel',
        label: 'Render'
      },
      {
        element: 'checkBox',
        className: 'ms-2',
        name: 'heatmap',
        items: ['total_SPL', 'direct_SPL', 'STI'],
        dispatcher: 'setShowMap'
      },
    ],
  },
  equipment_list: {
    container: [],
    area: [
      {
        element: 'checkBox',
        className: 'ms-2',
        name: 'list',
        items: ['summary', 'positioning_Detail'],
        dispatcher: 'setShowTable'
      },
      {
        condition: { parameter: ['coverage'], check: ([x]) => x.none },
        element: 'plainText',
        type: 'p',
        content: 'Current area has coverage set to none'
      },
    ]
  }

}

export default menus;

