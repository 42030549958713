import toolIndex from "toolConstants";
import ReducersIndex from 'reduxModules/ducks';
import { getDeviceLayouts } from "reduxModules/ducks/room";
import { getArea, getAreaState } from "reduxModules/ducks/container";
import { isEmpty, isEqual } from "lodash";

// List of actions that should trigger a recalculation
const triggerActions = [
  'container/setDimensions',
  'container/setCeilingHeight',
  'container/setCeilingTiles',
  'container/setTable',
  'container/setAcoustics',
  'container/setMicrophones',
  'container/setSpeakers',
  // add speaker and camera changes that would trigger recalculation here note:
  'ui/clearModals' // This is a safety check for the default room 
];

const calculateDevicesMiddleware = ({ dispatch, getState }) => next => action => {
  const state = getState();
  const constants = toolIndex[state.ui?.tool]?.constants;

  // Get flag to allow local caculation
  const localCalc = constants?.tool?.localCalc ?? false;


  // Set the customized flags when adding devices
  if (action.type === ReducersIndex.container.addDevice.type) {
    const currentDevice = state.ui.currentDevice
    const customParameters = constants.customSettings[currentDevice];
    dispatch(ReducersIndex.container.setCustomized(customParameters));
  }








  // todo: this will be updted and or mocved to logic (reset room)
  if (triggerActions.includes(action.type) && localCalc) {

    // Get previous state to avoid excesive dispatches
    const areaDefined = !isEmpty(getArea(state));
    const previousLayout = areaDefined ? getDeviceLayouts(state) : {};

    // Let the next action go thru
    next(action);

    // resets
    // this section checks what is changing so the devices can reset (set qty=0) and be recalculated
    // This is still in the works !!
    const { customized: { microphones: isMicCustom, speakers: isSpkrCustom } } = getAreaState(state)


    // This resets microphones when any microphone setting comes
    // This is temporary
    if (action.type === ReducersIndex.container.setMicrophones.type) {
      if(isMicCustom) {
        dispatch(ReducersIndex.container.setCustomized());
      }
    }

    // This resets speakers when any microphone setting comes
    // This is temporary
    if (action.type === ReducersIndex.container.setSpeakers.type) {
      if(isSpkrCustom) {
        dispatch(ReducersIndex.container.setCustomized());
      }
    }


    switch (triggerActions.indexOf(action.type)) {
      // cases that reset always. These intentional fall through to 7
      case 0: // Room Dimensions 
      case 1: // Ceiling Height
      case 5: // microphones - style, height, coverage, value
        if ('qty' in action.payload || 'coverageDensity' in action.payload) break
      case 7: // clearModals - safety. make certain than mics are calculated after existing modals
        dispatch(ReducersIndex.container.setMicrophones({ qty: 0 }))
        break;

      // Cases that reset only if mics are not customized. These intentionally fall thru to 4
      case 2: // ceiling tile changes
      case 3: // Table changes
      case 4: // Acoustics
        if (!isMicCustom) dispatch(ReducersIndex.container.setMicrophones({ qty: 0 }))
        break;

      // Don't reset ever
      // case 5: // Speakers
      //   break;
      default:
      // Nothing happens
    };


    // Update mic coverage for ceiling mics only on table shape change
    // fixme: this should only apply to ceiling mics 
    if (action.type === 'container/setTable' && 'shape' in action.payload) {
      const newShape = Boolean(action.payload.shape);
      if (!isMicCustom) dispatch(ReducersIndex.container.setMicrophones({ coverage: { full_room: !newShape, focused: newShape } }))
      return // Stop propagation to prevent loops 
    };

    // Update spkr model on mic change when selecting bars 
    if (action.type === 'container/setMicrophones' && 'model' in action.payload) {
      // console.log("new Micmodel: ", action.payload);

      // Get microphone type
      const { model: microphoneModel } = action.payload;
      const { microphones: { [microphoneModel.replace('mic', '_mic')]: { isBarMic = false } } } = constants.equipment;

      // Current speaker
      const speakerModel = state.container.areas[state.container.activeTab].speakers.model
      const { speakers: { [speakerModel]: { isBarSpkr = false } } } = constants.equipment //.speakers[speakerModel];
      const newSpeakerModel = (isBarMic && !isBarSpkr) ? 'barspkr' : (!isBarMic && isBarSpkr) ? 'ceilingspkr' : speakerModel;
      if (newSpeakerModel !== speakerModel) {
        // console.log("updating speaker model: ", newSpeakerModel);
        dispatch(ReducersIndex.container.setSpeakers({ model: newSpeakerModel }));
        return // stop propagation to prevent loops

      }


    }



    // todo: Setting mic to videobar set camera to false
    // todo: audio/videobar change family to Parle


    // Using timeout allows the store to update properly before calculating 

    setTimeout(() => {
      const state = getState();
      const currentLayout = getDeviceLayouts(state)
      const layoutChanged = !isEqual(previousLayout, currentLayout) || action.type === 'ui/clearModals';
      if (layoutChanged) dispatch(ReducersIndex.container.updateDevice(currentLayout))
    }, 0);

  }

  next(action)



};

export default calculateDevicesMiddleware;