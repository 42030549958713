/**
 * @module warehouseDesignerConstants
 * @description Constants for the Warehouse Designer tool.
 */
import { distanceToString } from "utilities/format";


const constants = {
  tool: {
    ver: '1.1.1',
    fullName: 'Warehouse Designer',
    path: {
      short: '/wd',
      long: 'warehouse_designer'
    },
    cookie: 'WDSeenTutorial',
    containerName: 'Warehouse',
    areaName: 'Area',
    maximumAreas: 16,
    addAreaLabel: 'AN AREA',
    landingMenu: 'speakers',
  },

  container: {
    min: 6.096, // 20' minimum container dimension
    max: 609.6, // 2000' maximum container dimension
    baySize: 3.657, // 12' loading dock bay dimensions
    travelLane: {
      min: 3.048, // 10'  
    },
  },

  roof: {
    min: 2.4384, // 8'
    max: 30.48, // 100'
  },

  roofGrid: {
    min: 0.6096, // 2' 
  },

  area: {
    minAreatoAdd: 9.2903, // available area needed to allow add 10sqft
    min: 3.048, // 10' minimum area dimension
    ceilingMin: 2.4384, // 8' minimum ceiling height 
    mountingMin: 1.9812, // 6.5617 minimum mounting height
    defMountingMax: 0.9144, // Max Mounting height 3' below ceiling
    defMountingMin: 1.2192, // Min Mounting height 1' below max, 4' below ceiling

    usage: {
      defaultUsage: 'palletRack',
      palletRack: {
        minLength: 5.4864, // 18' minimum length to have racks 
        minWidth: 6.7056, // 22' minimum width to have racks 
        minHeight: 2.4384, // 8' minimum upright 
        fallback: 'pallet'
      }
    },

    storage: {
      beam: 2.4383, // 96" long beam
      depth: 1.2192, // 4' rack depth
      pickSize: 6.096, // 20' rack-aisles-rack
      minPickSize: 3.657, // 12' rack-aisle-rack based on Order Picker width requirement
      minPrimarySize: 7.3152, // 24' rack-aisles-rack
    },

    tooltips: {
      palletRack: 'Pallet Racks',
      automatedHandling: 'Automated Material Handling',
      manualHandling: 'Manual Material Handling',
      pallet: 'Pallet Staging',
      trafficArea: 'Traffic Area',
      pagingbgm: 'Paging and Background Music',
      paging: 'Paging Only'

    },
  },

  noiseProfiles: {
    noiselow: 60,
    noisemedium: 65,
    noisehigh: 70,
    noiseextreme: 75
  },

  /**
   * @typedef {Object} PlotConfig
   * @property {string} label - The display label for the plot.
   * @property {number|string} min - The minimum value for the plot legend, can be a fixed number or a key referencing a dynamic value.
   * @property {number|string} max - The maximum value for the plot legend, can be a fixed number or a key referencing a dynamic value.
   * @property {string} source - The key for the data source matrix in the state.
   * @property {number} accuracy - The precision factor for calculations (e.g., 10 for one decimal place).
   * @property {string} [units] - The units of measurement for the plot values.
   * @property {string} avg - The key for the average value in the state.
   * @property {string} std - The key for the standard deviation in the state.
   * @property {number} [roundTo] - The number of decimal places to round the values to.
   * @property {Object} [labels] - Custom labels for specific value ranges.
   */

  /**
   * @typedef {Object} DevicePlotConfig
   * @property {string} default - The key of the default plot to display.
   * @property {Object.<string, PlotConfig>} plots - A collection of plot configurations keyed by plot type.
   */

  /**
   * Configuration for device-specific plots in the Warehouse Designer.
   * @type {Object.<string, DevicePlotConfig>}
   * @description Defines the plot configurations for different devices in the Warehouse Designer.
   * Currently includes configurations for speakers, but can be extended to other devices.
   * Each device has a default plot and a collection of available plots.
   */  
  devicePlots: {
    speakers: {
      default: 'directSPL',
      plots: {
        directSPL: {
          label: 'Direct SPL',
          min: 'directAwtdSplLegendMin',
          max: 'directAwtdSplLegendMax',
          source: 'directAwtdSplMatrix',
          accuracy: 10,
          units: 'dBA',
          avg: 'directAwtdSpl',
          std: 'directAwtdSplStd'
        },
        totalSPL: {
          label: 'Total SPL',
          min: 'totalAwtdSplLegendMin',
          max: 'totalAwtdSplLegendMax',
          source: 'totalAwtdSplMatrix',
          accuracy: 10,
          units: 'dBA',
          avg: 'totalAwtdSpl',
          std: 'totalAwtdSplStd'
        },
        STI: {
          label: 'Estimated STI',
          min: 0,
          max: 1,
          source: 'stiWithNoiseMaskingMatrix',
          accuracy: 1000,
          roundTo: 2,
          labels: {
            BAD: .15,
            POOR: .375,
            FAIR: .525,
            GOOD: .675,
            EXCELLENT: .875
          },
          avg: 'stiWithNoiseMasking',
          std: 'stiWithNoiseMaskingStd',
        }
      }
    }
  },

  iconMultiplier: 0.02,
  devices: ['speakers'],


  // These determine the values that are passed to the store when something is customized
  // i.e. When speaker.model is custom, systemPrymnaryUse is set to null and budget to empty string 
  // coverage is not here because I need to maintain the current value for the API - it's only hidden in UI
  customSettings: {
    speaker: {
      model: {
        systemPrimaryUsage: null,
        budget: '',
      },
    }
  },

  // These define which parameters prompt to undo customization 
  customReseters: ['dimensions', 'areaUsage', 'storageOrientation', 'systemPrimaryUsage', 'budget', 'coverage', 'speakerAiming', 'deleteSpeaker'],

  // API function to get current models: GetAvailableSpeakerModels
  //todo: name is not used or needed 
  equipment: {
    'H10-G': { name: 'H10', link: '#', description: 'Compact industrial  Horn' },
    'H20-G': { name: 'H20', link: '#', description: 'Weatherproof Industrial Horn' },
    'H30LT-G': { name: 'H30LT', link: '#', description: 'Industrial Compression Driver Horn' },
    'MPLT62-G': { name: 'MPLT62', link: '#', description: 'Two-Way Long Throw Music Projector' },
    'R.25-94TZ': { name: 'R.25-94', link: '#', description: 'Two-Way Coaxial 90° x 40° Horn w/Transformer' },
    'R.5-94TZ': { name: 'R.5-94Z', link: '#', description: 'Two-Way Full Range Sound Projector w/Transformer' },
    'R.35-3896': { name: 'R.35-3896', link: '#', description: 'Triaxial Three-Way Full Range Loudspeaker' },
    'R.15-3696' : {name: 'R.15-3696', link: '#', description: 'Triaxial Three-Way Full Range Loudspeaker'},
    //'CCA-80' : {name: 'CCA-80', link: '#', description: 'Triaxial Constant Coverage Aisle Loudspeaker'}
  },

  equipmentLists: {
    summary: {
      title: (name, isEmpty) => `${name} ${isEmpty ? 'does not have equipment' : 'equipment list'}`,
      widths: [15,20,65],
      tableData: (array) => array.reduce((acc, curr, index) => {
        const prod = acc.find(x => x.product.name === curr['modelName']);
        prod ? prod.quantity++ : acc.push({
          quantity: 1,
          product: { name: curr['modelName'], link: curr['modelName'] in constants.equipment ? constants.equipment[curr['modelName']].link : null },
          description: curr['modelName'] in constants.equipment ? constants.equipment[curr['modelName']].description : 'Description not available',
        });
        return acc
      }, []),
      notes: []
    },
    positioningDetail: {
      device: () => 'speakers',
      title: (name, isEmpty) => `${name} ${isEmpty ? 'does not have equipment' : 'loudspeaker positioning detail'}`,
      widths: [4, 12, 11, 11, 11, 10, 10, 10, 10, 11],
      tableData: (array, isMeter) => array.reduce((acc, curr, index) => {
            acc.push({
              '#': `${index + 1}`,
              'model': curr.modelName,
              'hor\ndist (x)': distanceToString(curr.location.x, isMeter), 
              'ver\ndist (y)': distanceToString(curr.location.y, isMeter), 
              'height\n(z)': distanceToString(curr.location.z, isMeter), 
              'hor\nangle': `${curr.horAngle} deg`,
              'ver\nangle': `${curr.verAngle} deg`,
              '70v Tap': `${curr.powerTap70V} Watts`,
              'gain': `${curr.gain} dB`,
              'delay': `${curr.delay} ms`
            });
          return acc
        }, []),
      notes: [
        'Horizontal and Vertical distances are referenced from top-left corner or diagram',
        'Height is measured from floor',
        'Horizontal angle is clockwise with 0 deg reference pointing up',
        'Vertical angle is referenced to the horizontal plane'
      ]
    }
  },


  // marker filter keys 
  // This are the keys I want to use to save 
  saveKeys: {
    uiKeys: ['apiversion', 'units', 'scale', 'savekey'],
    containerKeys: ['project', 'name', 'dimensions', 'loadingDocks', 'travelLane', 'ghostDocks', 'roofType', 'roofSlopeOrientation', 'roofHeight',
      'roofGrid', 'roofGridSpacing', 'roofGridOffset', 'materials', 'budget'],
    // future requestQuote, areaQty?
    areaKeys: ['id', 'name', 'areaType', 'dimensions', 'location', 'openCeiling', 'snapToGrid', 'ceilingHeight', 'mountingHeight', 'surroundingWalls',
      'areaUsage', 'storageOrientation', 'uprightHeightFt', 'pickingAisles', 'primaryAisles', 'connectorAisles', 'noiseLevel', 'backgroundNoise', 'materials', 'coverage', 'systemPrimaryUsage',
      'budget', 'speakerAiming', 'rackFill', 'speakers', 'customized', 'applyDelay'],
  },



  // keys I don't need to send to the API (API calls only care about container stuff )
  callFilter: {
    container: ['activeTab', 'areaToUpdate', 'ghostDocks', 'name', 'project'],
    area: (remap = false) => [...['directAwtdSpl', 'directAwtdSplStd', 'totalAwtdSpl', 'totalAwtdSplStd', 'totalPriceMsrp', 'speakerLayout',
      'directAwtdSplLegendMax', 'directAwtdSplLegendMin', 'totalAwtdSplLegendMax', 'totalAwtdSplLegendMin', 'areaType', 'backgroundNoise'], ...(remap ? [] : ['speakers'])],
  },


  // parameters required for recalculation
  containerParams: [
    "dimensions",
    "roofType",
    "roofSlopeOrientation",
    "roofHeight",
    "roofGridSpacing",
    "roofGridOffset",
    "backgroundNoise", // ?
    "noiseLevel", // ?
    "materials",
    "budget",  // ?
    "areas"
  ],
  areaParams: [
    "dimensions",
    "location",
    "ceilingHeight",
    "mountingHeight",
    "surroundingWalls",
    "areaUsage",
    "storageOrientation",
    "uprightHeightFt",
    "pickingAisles",
    "primaryAisles",
    "connectorAisles",
    "noiseLevel",
    "materials",
    "coverage",
    "systemPrimaryUsage",
    "budget",
    "speakerAiming",
    "rackFill",
    "openCeiling",
    "snapToGrid",
    "customized.qty",
    "applyDelay"

  ],
};

export default constants;