import { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import Input from "components/atoms/Input";
import Button from "components/molecules/Button";
import ReducersIndex from "reduxModules/ducks";
import { buttonThemes } from "globalConstants/Themes";
import { keys } from "lodash";

const EditableButton = (props) => {
  const id = props.id;
  const name = useSelector(state => (state.container.areas[id] || state.container).name);
  const [label, setLabel] = useState();
  const [editing, setEditing] = useState(false);
  const theme = buttonThemes[props.active ? 'nav_black' : 'nav_gray'];
  const canDelete = useSelector(state => keys(state.container.areas).includes(id));

  useEffect(() => {
    setLabel(name);
  }, [name]);

  const dispatch = useDispatch();

  const setName = (evt) => {
    setEditing(false);
    const name = evt.target.value;
    if (name) dispatch(ReducersIndex.container.setName({ id: id, name: name }));
  };

  const handleDelete = (evt) => {
    dispatch(ReducersIndex.container.setAreaToUpdate({id: id}));
    dispatch(ReducersIndex.uiReducers.openModal('deleteArea'));
  };

  return (
    <div className="hstack gap-0" > 
      {editing ?
        <div className="hstack gap-0 border-start border-5 border-dark">
          {/* todo: duplicate and delete actions  */}
          <Input className={'w-100 h-auto'} defaultValue={label} autoFocus onFocus={(e) => e.target.select()} onBlur={(evt) => setName(evt)} />
          {/* {canDuplicate && <Button name={'duplicate'} />} */}
          {canDelete && <Button name={'delete'} onMouseDown={(evt) => handleDelete(evt)}/>}
        </div>
        :
        <Button label={label} theme={theme} onClick={props.onClick} editable />
      }
      {props.active && <Button name={'edit'} theme={theme} state={'nav_active'} onClick={() => setEditing(true)} />}
    </div>
  )
};

export default EditableButton

