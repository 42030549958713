import styled from 'styled-components';
import { useState, useContext, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import ButtonGroup from 'components/molecules/ButtonGroup';
import ToolContext from 'components/services/ToolContext';
import ZoomButton from 'components/molecules/ZoomButton';
import UndoButton from 'components/molecules/UndoButton';
import ReducersIndex from 'reduxModules/ducks';
import { ActionText } from 'globalConstants/Icons/Actions';
import { getArea, getAreaProperty, getAvailableActions, getCurrentValue } from 'reduxModules/ducks/container';
import { get, some, trimEnd } from 'lodash';


const ActionBar = (props) => {
  const currentView =  useSelector(state => getCurrentValue(state, 'currentView')); // active menu
  const showing = useSelector(state => getCurrentValue(state, 'showing')); // container or area 
  const {actions: act, actionConditions: conditions} = useContext(ToolContext);
  const items = ['measure'].concat(act?.[currentView]?.[showing] ?? []); // list of possible actions to show 
  const actions = useSelector(state => getAvailableActions(state, {items: items, conditions: conditions}));
  const currentDevice = useSelector(state => trimEnd(getCurrentValue(state, 'currentDevice'), 's'));

  const dispatch = useDispatch();
  const button = useSelector(state => state.ui.action)
  const [buttonActive, setButtonActive] = useState(button);

  useEffect(() => {
    // setButtonActive(button)
    if(items.includes(button)) setButtonActive(button)
    if (!button) dispatch(ReducersIndex.uiReducers.setAction(buttonActive));
  }, [button])

  const [toolTip, setToolTip] = useState('');
  const [delay, setDelay] = useState('0s');
  const handleEnter = (evt, item) => {
    setToolTip(ActionText[item](currentDevice));
    setDelay('0s');
  };

  const handleLeave = () => setDelay('.5s');
    
  const handleClick = (evt, item) => {
    setButtonActive(item);
    setDelay('2.5s');
    dispatch(ReducersIndex.uiReducers.setAction(item));
  };
  const getState = (item) => buttonActive === item  ? 'button_selected' : 'button_disabled';
  const checked = (item) => buttonActive === item;

  props = {...props,
    items: actions, 
    // conditions: conditions,
    handleClick: handleClick,
    handleEnter: handleEnter,
    handleLeave: handleLeave,
    getState: getState,
    checked: checked,
  };
  
  const hasContainer = useContext(ToolContext).constants.tool.containerName
  const inArea = useSelector(getArea);
  const showUndo = useSelector(state => some(getAreaProperty(state, 'customized')));

  return (
    <ActionBar.Wrapper >
      {hasContainer && inArea && <ZoomButton {...props} />}
      <ButtonGroup theme={'actionGroup'} {...props} />
      {showUndo && <UndoButton {...props} />}
      <ActionBar.ToolTip delay={delay}>{toolTip}</ActionBar.ToolTip>
      
    </ActionBar.Wrapper>
  )
};

ActionBar.Wrapper = styled.div.attrs(props => ({
  className: 'my-1 hstack'
}))`
`;

ActionBar.ToolTip = styled.div.attrs(props => ({
  className: 'ms-2'
}))`
  color: var(--app-red);
  opacity: ${props => props.delay === '0s' ? 1 : 0};
  transition: ${props => `${props.delay === '0s' ? '0.2s' : '0.5s'} opacity ease ${props.delay}`};
`;

export default ActionBar;