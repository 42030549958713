/**
 * @module DeviceDrawing
 * @description Module for rendering various audio devices (speakers, microphones, horns) in a SVG context.
 */

import styled from "styled-components";
import { useMemo, useContext } from "react";
import { useSelector } from "react-redux"
import { getDrawingBox } from "reduxModules/ducks/ui";
import { getConditionResult, getCurrentValue2 } from "reduxModules/ducks/container";
import ToolContext from "components/services/ToolContext";
import icons from "globalConstants/Icons";
import { iconThemes } from "globalConstants/Themes";

/**
 * Generic component for rendering devices.
 * @function
 * @param {Object} props - Component props
 * @param {string} props.id - Unique identifier for the device group
 * @param {string} props.type - Type of device ('speakers', 'microphones')
 * @param {string} props.icon - Icon identifier
 * @param {Object} props.origin - Origin coordinates {left, top}
 * @param {Array} props.devices - Array of device data
 * @param {Object} props.show - Condition for showing the devices
 * @param {Object} props.active - Condition for setting devices as active
 * @returns {React.Element} SVG group element containing device icons
 */
const Devices = (props) => {
  const { bbox, scale, zoom, iconAdj } = useSelector(getDrawingBox);
  const { left, top } = props.origin;
  const x0 = bbox.x0 + left * scale * (!zoom);
  const y0 = bbox.y0 + top * scale * (!zoom);

  const Icon = useMemo(() => icons[props.icon], [props.icon]);
  const iconSize = 46;
  const iconScale = Math.max(10 / iconSize, scale * iconAdj); //wd = 10 & 50 CRD=180 

  const array = props.devices.map((dev, index) => ({
    id: `${props.id}-${props.type}-${index}`,
    transform: `translate(${x0 + dev.location.x * scale}, ${y0 + dev.location.y * scale}) scale(${iconScale})`,
    angle: dev.horAngle !== undefined ? dev.horAngle - 90 : 0,
  }))

  const visible = useSelector(state => getConditionResult(state, { ...props.show, area: props.id }));
  const active = useSelector(state => getConditionResult(state, { ...props.active, area: props.id }));

  const Arrow = icons['speakerArrow'];
  const shoudShowArrow = props.devices.length > 0 ? 'horAngle' in props.devices[0] : false;

  // Get the current action to set whether the icons will receive pointer events or not
  const currentAction = useSelector(state => getCurrentValue2(state, 'action'));
  const isIconClickable = active && ['remove', 'move'].includes(currentAction);

  const state = iconThemes[active ? 'button_active' : 'button_disabled-dim'];

  return (
    visible && <Devices.Group id={`${props.id}-${props.type}`} isIconClickable={isIconClickable}>
      {array && array.map((device, i) =>
        <g key={device.id} className={props.type} id={device.id} transform={device.transform}>
          <Icon state={state} />
          {shoudShowArrow && <Arrow state={state} angle={device.angle} />}
        </g>
      )}
    </Devices.Group>
  )
};

/**
 * Styled component for the device group.
 * @component
 */
Devices.Group = styled.g.attrs(props => ({
  id: props.id
}))`
  pointer-events: ${props => props.isIconClickable ? 'auto' : 'none'};
`;

/**
 * Component for rendering horn speakers.
 * @function
 * @param {Object} props - Component props
 * @param {Object} props.children - Area data
 * @returns {React.Element} Devices component configured for horns
 */
const Horns = (props) => {
  const area = props.children;
  return (
    <Devices {...props} id={area.id} type={'speakers'} icon={'speaker'} origin={area.location} devices={area.speakers ?? []} />
  )
};

/**
 * Component for rendering speakers.
 * @function
 * @param {Object} props - Component props
 * @param {Object} props.children - Area data
 * @returns {React.Element} Devices component configured for speakers
 */
const Speakers = (props) => {
  const area = props.children;
  const { speakers } = area;
  const spkrType = speakers.model;
  const context = useContext(ToolContext);
  const icon = context.constants.equipment.speakers[spkrType].icon(area);
  return (
    <Devices {...props} id={area.id} type={'speakers'} icon={icon} origin={area.location} devices={speakers.layout} />
  )
};

/**
 * Component for rendering microphones.
 * @function
 * @param {Object} props - Component props
 * @param {Object} props.children - Area data
 * @returns {React.Element} Devices component configured for microphones
 */
const Microphones = (props) => {
  const area = props.children;
  const { microphones } = area
  const micType = microphones.model.replace('mic', '_mic');
  return (
      <Devices {...props} id={area.id} type={'microphones'} icon={micType} origin={area.location} devices={microphones.layout} />
  )

};

export { Speakers, Microphones, Horns }
/**
 * @note
 * 1. The Horns and Speakers components are similar in functionality but differ in two key aspects:
 *    - Data source: They retrieve device data from different properties of the area object.
 *    - Icon selection: 
 *      - Horns always use the 'speaker' icon.
 *      - Speakers determine the icon based on the current device selected, using the `icon` function 
 *        from the equipment constants.
 * 2. This separation allows for flexible rendering of different types of speaker devices while 
 *    maintaining a consistent interface.
 * 3. The Microphones component follows a similar pattern but is tailored for microphone devices.
 * 4. All components utilize the generic Devices component for actual rendering, providing a 
 *    consistent look and behavior across different device types.
 */
