import { createSelector, createSlice, current } from "@reduxjs/toolkit";
import { createCachedSelector } from 're-reselect';
import { uiReducers, getZoom, getUIProperty, getIsMeter } from "./ui";
import toolIndex from "toolConstants";
import { camelizeObject, getStoreProp } from "utilities/string";
import { getRectangle, isInside, getSurface } from "utilities/geometric";
import { findLargest } from "utilities/mathematic";
import { parseDistance, toFeet, toMeter } from "utilities/format";
import devicePlots from "globalConstants/devicePlots";
import { keys, values, toPairs, findKey, reject, startsWith, includes, omit, set, isEmpty, has, some, isObject, get, isString, remove, round, toString, capitalize } from "lodash";

import roomSlice from "./room";
import classroomSlice from "./classroom";

// This initial state is preloaded for warehouse
const initialState = {
  currentTool: "",
  activeTab: "",
  currentDevice: "",
  // showMap: 'directSPL',
  project: {
    name: "",
    company: "",
    designer: "",
  },
  name: "Warehouse",
  dimensions: {
    length: 182.88,
    width: 91.44,
  },

  // loading docks 
  loadingDocks: {
    top_docks: false,
    right_docks: false,
    bottom_docks: false,
    left_docks: true,
  },
  travelLane: { width: 9.7536 },
  ghostDocks: [],

  // using this to update/delete docks at the moment
  areaToUpdate: null,
  // delete: false,

  // roof
  roofType: "slope",
  roofSlopeOrientation: "slopeBottom",
  roofHeight: {
    min: 7.0104,
    max: 8.9408,
  },
  roofGrid: false,
  roofGridSpacing: { x: 2.4384, y: 1.2192 },
  roofGridOffset: { dx: 0, dy: 0 },

  // materials
  materials: {
    walls: 'CMU_Unpainted',
    ceiling: 'Metal_Deck',
  },

  budget: 'Standard',
  areas: {},
  // recalculate: false,
  // remap: false,
};
/**
 * Container Slice for managing the overall container state in the Redux store.
 * @type {import("@reduxjs/toolkit").Slice}
 */
const containerSlice = createSlice({
  name: "container",
  initialState: { ...initialState },
  reducers: {
    /**
     * Sets the project data, including areas and container properties.
     * @param {Object} state - The current state.
     * @param {Object} action - The action object.
     * @param {Object} action.payload - The project data to set.
     * @param {Object} [action.payload.areas] - The areas data to set.
     */
    setProjectData: (state, action) => {
      const { areas = {}, ...container } = action.payload || {};
      keys(state).forEach(key => {
        if (key in container) state[key] = container[key];
      });
      values(areas).forEach(area => {
        const newArea = { ...toolIndex[state.currentTool].initialState.area(state), storageReady: true, remap: area.speakers.length >= 1 };
        state.areas = {
          ...state.areas,
          [area.id]: {
            ...newArea,
            ...area,
            speakerModel: area.speakers?.[0]?.modelName ?? '',
          }
        };
      });
    },

    /**
     * Sets project properties.
     * @param {Object} state - The current state.
     * @param {Object} action - The action object.
     * @param {Object} action.payload - The project properties to update.
     * @param {string} [action.payload.name] - The project name.
     * @param {string} [action.payload.company] - The company name.
     * @param {string} [action.payload.designer] - The designer name.
     */
    setProject: (state, action) => {
      state.project = { ...state.project, ...action.payload };
    },

    /**
     * Sets the name of an area or the container.
     * @param {Object} state - The current state.
     * @param {Object} action - The action object.
     * @param {Object} action.payload - The new name data.
     * @param {string} action.payload.name - The new name.
     * @param {string} [action.payload.id] - The ID of the area to rename (if applicable).
     */
    setName: (state, action) => {
      (state.areas[state.activeTab] || state).name = action.payload.name;
    },

    /**
     * Adds a new area to the container.
     * @param {Object} state - The current state.
     * @param {Object} action - The action object.
     * @param {Object} [action.payload] - Optional payload for area data.
     * @param {string} [action.payload.areaToAdd] - The ID of the area template to use.
     */
    addArea: (state, action) => {
      console.log('adding area');
      const baseName = toolIndex[state.currentTool].constants.tool.areaName;
      const baseId = keys(state.areas).reduce((acc, curr) => Math.max(curr.match(/\d+/g), acc), 0) + 1;
      const areaName = `${baseName} ${baseId}`
      const newId = `_${baseName.toLowerCase()}${baseId}`;
      const areaToAdd = action?.payload?.areaToAdd;
      console.log("id: ", newId, areaName);

      const { id = newId, name = areaName, areaType = null } = state[areaToAdd] || {};
      const newArea = { ...toolIndex[state.currentTool].initialState.area(state) };
      newArea.id = id;
      newArea.name = name;
      if (areaType) newArea.areaType = areaType;
      if (toolIndex[state.currentTool].constants.tool.containerName) toolIndex[state.currentTool].setArea(state, newArea);
      state.areas = { ...state.areas, [id]: newArea };
      state.activeTab = newId;
      state.areaToUpdate = null;
    },

    /**
     * Deletes an area from the container.
     * @param {Object} state - The current state.
     */
    deleteArea: (state, action) => {
      const { [state.areaToUpdate.id]: deleted, ...areas } = state.areas;
      state.areas = { ...areas };
      if (deleted.areaType === 'dock' && state.loadingDocks[deleted.id]) {
        containerSlice.caseReducers.setLoadingDocks(state, { ...action, payload: deleted.id });
      };
      state.areaToUpdate = null;
    },

    /**
     * Updates an area based on the areaToUpdate state.
     * @param {Object} state - The current state.
     * @param {Object} action - The action object.
     * @param {boolean} action.payload - Whether to modify the area.
     */
    updateArea: (state, action) => {
      const modify = action.payload;
      const { area, update } = state.areaToUpdate; // , flag = false 
      const { parameter, value } = update;
      if (modify) toolIndex[state.currentTool].resetArea(state, parameter);
      const newValue = modify ? value : state.areas[area][parameter];
      state.areas[area] = { ...state.areas[area], [parameter]: isObject(newValue) ? { ...state.areas[area][parameter], ...newValue } : newValue };
      state.areaToUpdate = null;
    },

    /**
     * Sets the dimensions of an area or the container.
     * @param {Object} state - The current state.
     * @param {Object} action - The action object.
     * @param {Object} action.payload - The new dimensions.
     * @param {number} [action.payload.length] - The new length.
     * @param {number} [action.payload.width] - The new width.
     */
    setDimensions: (state, action) => {
      const { length, width } = action.payload;
      const { length: currentLength, width: currentWidth } = (state.areas[state.activeTab] || state).dimensions;
      (state.areas[state.activeTab] || state).dimensions = {
        length: length || currentLength,
        width: width || currentWidth,
      };
      // Area resets - clear area stuf when dims change 
      if (state.activeTab in state.areas && ((length && length !== currentLength) || (width && width !== currentWidth))) {
        // toolIndex[state.currentTool].resetContents(state)
        toolIndex[state.currentTool].resetArea(state, action.type)
      };
    },

    /**
     * Sets the loading dock status for a specific side.
     * @param {Object} state - The current state.
     * @param {Object} action - The action object.
     * @param {string} action.payload - The side to toggle ('top_docks', 'right_docks', 'bottom_docks', 'left_docks').
     */
    setLoadingDocks: (state, action) => {
      const side = action?.payload;
      const curr = state.loadingDocks[side];
      if (side) {
        state.loadingDocks = { ...state.loadingDocks, [side]: !state.loadingDocks[side] };
      }
      if (curr) {
        state.ghostDocks = reject(state.ghostDocks, dock => startsWith(dock, side.split('_')[0]));
      };
    },

    /**
     * Sets the area to update.
     * @param {Object} state - The current state.
     * @param {Object} action - The action object.
     * @param {Object} action.payload - The area update information.
     * @param {string} action.payload.id - The ID of the area to update.
     * @param {string} [action.payload.name] - The new name for the area.
     * @param {string} [action.payload.areaType] - The type of the area.
     */
    setAreaToUpdate: (state, action) => {
      state.areaToUpdate = action.payload
    },

    /**
     * Sets the travel lane properties.
     * @param {Object} state - The current state.
     * @param {Object} action - The action object.
     * @param {Object} action.payload - The travel lane properties.
     * @param {number} action.payload.width - The width of the travel lane.
     */
    setTravelLane: (state, action) => {
      state.travelLane = action.payload
    },

    /**
     * Toggles the ghost dock status for a specific dock.
     * @param {Object} state - The current state.
     * @param {Object} action - The action object.
     * @param {string} action.payload - The dock identifier to toggle.
     */
    setGhostDocks: (state, action) => {
      const current = [...state.ghostDocks];
      const dock = action.payload;
      current.includes(dock) ? current.splice(current.indexOf(dock), 1) : current.push(dock);
      state.ghostDocks = [...current]
    },

    /**
     * Sets the roof type.
     * @param {Object} state - The current state.
     * @param {Object} action - The action object.
     * @param {string} action.payload - The new roof type ('slope' or 'aframe').
     */
    setRoofType: (state, action) => {
      if (state.roofType !== action.payload)
        state.roofSlopeOrientation = action.payload === 'aframe' ? 'vertical' : 'slopeRight';
      state.roofType = action.payload;
    },

    /**
     * Sets the roof slope orientation.
     * @param {Object} state - The current state.
     * @param {Object} action - The action object.
     * @param {string} action.payload - The new roof slope orientation.
     */
    setRoofSlopeOrientation: (state, action) => {
      state.roofSlopeOrientation = action.payload;
    },

    /**
     * Sets the roof height.
     * @param {Object} state - The current state.
     * @param {Object} action - The action object.
     * @param {Object} action.payload - The new roof height.
     * @param {number} [action.payload.min] - The minimum roof height.
     * @param {number} [action.payload.max] - The maximum roof height.
     */
    setRoofHeight: (state, action) => {
      const { min, max } = action.payload;
      const { min: currentMin, max: currentMax } = state.roofHeight;
      // NOTE: this might get simplified if we check in middleware
      state.roofHeight = {
        min: min ? min : (!max || currentMin < max) ? currentMin : max,
        max: max ? max : (!min || currentMax > min) ? currentMax : min || 0
      }
    },

    /**
     * Toggles the roof grid.
     * @param {Object} state - The current state.
     */
    setRoofGrid: (state, action) => {
      state.roofGrid = !state.roofGrid
    },

    setRoofGridSpacing: (state, action) => {
      const { x, y } = action.payload;
      const { x: currentX, y: currentY } = state.roofGridSpacing;
      state.roofGridSpacing = {
        x: x || currentX,
        y: y || currentY,
      };
    },

    /**
     * Sets the roof grid spacing.
     * @param {Object} state - The current state.
     * @param {Object} action - The action object.
     * @param {Object} action.payload - The new roof grid spacing.
     * @param {number} [action.payload.x] - The x-axis spacing.
     * @param {number} [action.payload.y] - The y-axis spacing.
     */
    setRoofGridOffset: (state, action) => {
      const { dx, dy } = action.payload;
      const { dx: currentDx, dy: currentDy } = state.roofGridOffset;
      const { x, y } = state.roofGridSpacing;
      state.roofGridOffset = {
        dx: dx !== undefined ? dx > x ? dx % x : dx : currentDx,
        dy: dy !== undefined ? dy > y ? dy % y : dy : currentDy,
      };
    },

    /**
     * Sets the background noise level.
     * @param {Object} state - The current state.
     * @param {Object} action - The action object.
     * @param {string|number} action.payload - The new background noise level or profile.
     */
    setBackgroundNoise: (state, action) => {
      containerSlice.caseReducers.setNoiseLevel(state, action);
    },

    /**
     * Sets the noise level.
     * @param {Object} state - The current state.
     * @param {Object} action - The action object.
     * @param {string|number} action.payload - The new noise level or profile.
     */
    setNoiseLevel: (state, action) => {
      const value = action.payload;
      const noiseProfiles = toolIndex[state.currentTool].constants.noiseProfiles;
      if (value in noiseProfiles) {
        state.areas[state.activeTab].noiseLevel = noiseProfiles[value];
      } else if (value) {
        state.areas[state.activeTab].noiseLevel = value;
        state.areas[state.activeTab].backgroundNoise = null;
      }
      const noise = findKey(noiseProfiles, key => key === value);
      if (noise) state.areas[state.activeTab].backgroundNoise = noise;
    },

    /**
     * Sets the materials for walls and ceiling.
     * @param {Object} state - The current state.
     * @param {Object} action - The action object.
     * @param {Object} action.payload - The new materials.
     * @param {string} [action.payload.walls] - The wall material.
     * @param {string} [action.payload.ceiling] - The ceiling material.
     */
    setMaterials: (state, action) => {
      const currentArea = state.areas[state.activeTab];
      if (currentArea) {
        currentArea.materials = { ...currentArea.materials, ...action.payload };
      } else {
        state.materials = { ...state.materials, ...action.payload };
        values(state.areas).forEach(area => {
          if (area.openCeiling) area.materials = { ...area.materials, ...action.payload }
        })
      };
    },

    /**
     * Sets the system primary usage for the current area.
     * @param {Object} state - The current state.
     * @param {Object} action - The action object.
     * @param {string} action.payload - The new system primary usage.
     */
    setSystemPrimaryUsage: (state, action) => {
      state.areas[state.activeTab].systemPrimaryUsage = action.payload;
      if (!state.areas[state.activeTab].customized.speakerModel) {
        state.areas[state.activeTab].speakerModel = null;
      }
    },

    /**
     * Sets the budget for the current area or container.
     * @param {Object} state - The current state.
     * @param {Object} action - The action object.
     * @param {string} action.payload - The new budget level.
     */
    setBudget: (state, action) => {
      (state.areas[state.activeTab] || state).budget = action.payload;
      if (!state.areas[state.activeTab].customized.speakerModel) {
        state.areas[state.activeTab].speakerModel = null;
      }
    },

    /**
     * Sets the speaker aiming for the current area.
     * @param {Object} state - The current state.
     * @param {Object} action - The action object.
     * @param {string} action.payload - The new speaker aiming setting.
     */
    setSpeakerAiming: (state, action) => {
      state.areas[state.activeTab].speakerAiming = action.payload;
      state.areas[state.activeTab].customized.qty = false;
    },

    /**
     * Sets the speaker model for the current area.
     * @param {Object} state - The current state.
     * @param {Object} action - The action object.
     * @param {string} action.payload - The new speaker model.
     */
    setSpeakerModel: (state, action) => {
      state.areas[state.activeTab].speakerModel = action.payload;
      state.areas[state.activeTab].customized.speakerModel = true;
    },

    /**
     * Deletes a speaker from a specific area.
     * @param {Object} state - The current state.
     * @param {Object} action - The action object.
     * @param {Object} action.payload - The deletion information.
     * @param {string} action.payload.area - The ID of the area containing the speaker.
     * @param {number} action.payload.id - The index of the speaker to delete.
     */
    deleteSpeaker: (state, action) => {
      const { area, id } = action.payload;
      if (state.activeTab === area) {
        state.areas[area].speakers.splice(id, 1);
        state.areas[area].remap = true;
        state.areas[area].customized.qty = true;
      }
    },

    /**
     * Toggles the apply delay flag for the current area.
     * @param {Object} state - The current state.
     * @param {Object} action - The action object (unused in this reducer).
     */
    setApplyDelay: (state, action) => {
      state.areas[state.activeTab].applyDelay = !state.areas[state.activeTab].applyDelay
    },

    /**
    * Sets the location of the current area.
    * @param {Object} state - The current state.
    * @param {Object} action - The action object.
    * @param {Object} action.payload - The new location.
    * @param {number} [action.payload.left] - The new left position.
    * @param {number} [action.payload.top] - The new top position.
    */
    setLocation: (state, action) => {
      if (!(state.activeTab in state.areas)) return;
      const { left, top } = action.payload;
      const { left: currentLeft, top: currentTop } = state.areas[state.activeTab].location;
      state.areas[state.activeTab].location = {
        left: left !== undefined ? left : currentLeft,
        top: top !== undefined ? top : currentTop,
      };
    },

    /* Toggles the open ceiling status for the current area.
     * @param {Object} state - The current state.
     */
    setOpenCeiling: (state, action) => {
      const currentArea = state.areas[state.activeTab];
      currentArea.openCeiling = !currentArea.openCeiling
      if (currentArea.openCeiling) {
        currentArea.ceilingHeight = {
          min: state.roofHeight.min,
          max: state.roofHeight.min
        };
        currentArea.materials.ceiling = state.materials.ceiling
      } else {
        currentArea.snapToGrid = false
      };
    },

    /**
     * Toggles the snap to grid status for the current area.
     * @param {Object} state - The current state.
     */
    setSnapToGrid: (state, action) => {
      state.areas[state.activeTab].snapToGrid = !state.areas[state.activeTab].snapToGrid
    },

    /**
     * Sets the ceiling height for the current area.
     * @param {Object} state - The current state.
     * @param {Object} action - The action object.
     * @param {Object} action.payload - The new ceiling height.
     * @param {number} [action.payload.min] - The minimum ceiling height.
     * @param {number} [action.payload.max] - The maximum ceiling height.
     */
    setCeilingHeight: (state, action) => {
      if (!(state.activeTab in state.areas)) return;
      const { min, max } = action.payload;
      const { min: currentMin } = state.areas[state.activeTab].ceilingHeight;
      state.areas[state.activeTab].ceilingHeight = {
        min: Math.max(0, min ? min : (!max || currentMin < max) ? currentMin : max),
        max: Math.max(0, min ? min : (!max || currentMin < max) ? currentMin : max),
      }
    },

    /**
     * Sets the mounting height for devices in the current area.
     * @param {Object} state - The current state.
     * @param {Object} action - The action object.
     * @param {Object} action.payload - The new mounting height.
     * @param {number} [action.payload.min] - The minimum mounting height.
     * @param {number} [action.payload.max] - The maximum mounting height.
     */
    setMountingHeight: (state, action) => {
      if (!(state.activeTab in state.areas)) return;
      const { min, max } = action.payload;
      const { min: currentMin, max: currentMax } = state.areas[state.activeTab].mountingHeight;
      // NOTE: this might get soimplified if we check in middleware
      state.areas[state.activeTab].mountingHeight = {
        min: Math.max(0, min ? min : (!max || currentMin < max) ? currentMin : max),
        max: Math.max(0, max ? max : (!min || currentMax > min) ? currentMax : min)
      }
    },

    /**
     * Toggles the presence of a surrounding wall for the current area.
     * @param {Object} state - The current state.
     * @param {Object} action - The action object.
     * @param {string} action.payload - The wall identifier (e.g., 'top', 'right', 'bottom', 'left').
     */
    setSurroundingWalls: (state, action) => {
      if (!(state.activeTab in state.areas)) return;
      const side = action.payload.replace("wall", "");
      state.areas[state.activeTab].surroundingWalls = { ...state.areas[state.activeTab].surroundingWalls, [side]: !state.areas[state.activeTab].surroundingWalls[side] };
    },

    /**
     * Sets the area usage for the current area.
     * @param {Object} state - The current state.
     * @param {Object} action - The action object.
     * @param {string} action.payload - The new area usage.
     */
    setAreaUsage: (state, action) => {
      if (!(state.activeTab in state.areas)) return;
      state.areas[state.activeTab].areaUsage = action.payload;
      // toolIndex[state.currentTool].resetContents(state);
      toolIndex[state.currentTool].resetArea(state);
    },

    /**
     * Sets the storage orientation for the current area.
     * @param {Object} state - The current state.
     * @param {Object} action - The action object.
     * @param {string} action.payload - The new storage orientation.
     */
    setStorageOrientation: (state, action) => {
      if (!(state.activeTab in state.areas)) return;
      state.areas[state.activeTab].storageOrientation = action.payload
      toolIndex[state.currentTool].resetArea(state);
    },

    /**
      * Sets the upright height in feet for the current area.
      * @param {Object} state - The current state.
      * @param {Object} action - The action object.
      * @param {number} action.payload - The new upright height in feet.
      */
    setUprightHeightFt: (state, action) => {
      if (!(state.activeTab in state.areas)) return;
      state.areas[state.activeTab].uprightHeightFt = parseInt(action.payload)
    },

    /**
     * Sets the number of picking aisles for the current area.
     * @param {Object} state - The current state.
     * @param {Object} action - The action object.
     * @param {number} action.payload - The new number of picking aisles.
     */
    setPickingAisles: (state, action) => {
      if (!(state.activeTab in state.areas)) return;
      state.areas[state.activeTab].pickingAisles = action.payload
    },

    /**
     * Sets the number of primary aisles for the current area.
     * @param {Object} state - The current state.
     * @param {Object} action - The action object.
     * @param {number|string} action.payload - The new number of primary aisles.
     */
    setPrimaryAisles: (state, action) => {
      if (!(state.activeTab in state.areas)) return;
      state.areas[state.activeTab].primaryAisles = (isNaN(action.payload) ? '' : action.payload)
    },

    /**
     * Sets the number of connector aisles for the current area.
     * @param {Object} state - The current state.
     * @param {Object} action - The action object.
     * @param {number} action.payload - The new number of connector aisles.
     */
    setConnectorAisles: (state, action) => {
      if (!(state.activeTab in state.areas)) return;
      state.areas[state.activeTab].connectorAisles = action.payload
      state.areas[state.activeTab].pickingAisles = 0
    },

    /**
     * Sets the storage ready status for the current area.
     * @param {Object} state - The current state.
     * @param {Object} action - The action object.
     * @param {boolean} action.payload - The new storage ready status.
     */
    setStorageReady: (state, action) => {
      if (!(state.activeTab in state.areas)) return;
      state.areas[state.activeTab].storageReady = action.payload
    },

    /**
     * Sets the rack arrays for a specific area.
     * @param {Object} state - The current state.
     * @param {Object} action - The action object.
     * @param {Object} action.payload - The rack arrays information.
     * @param {string} action.payload.area - The ID of the area to update.
     * @param {Array} action.payload.arrays - The new rack arrays.
     */
    setRackArrays: (state, action) => {
      const area = action.payload.area
      state.areas[area].rackArrays = action.payload.arrays
    },

    /**
     * Sets the aisles array for a specific area.
     * @param {Object} state - The current state.
     * @param {Object} action - The action object.
     * @param {Object} action.payload - The aisles array information.
     * @param {string} action.payload.area - The ID of the area to update.
     * @param {Array} action.payload.array - The new aisles array.
     */
    setAislesArray: (state, action) => {
      // if (!(state.activeTab in state.areas)) return;
      const { area, array } = action.payload;
      state.areas[area].aislesArray = array
    },

    /**
     * Sets the coverage properties for the current area.
     * @param {Object} state - The current state.
     * @param {Object} action - The action object.
     * @param {Object} action.payload - The new coverage properties.
     */
    setCoverage: (state, action) => {
      if (!(state.activeTab in state.areas)) return;
      state.areas[state.activeTab] = {
        ...state.areas[state.activeTab],
        coverage: { ...state.areas[state.activeTab].coverage, ...action.payload },
        speakerAiming: 'aimAuto',
        customized: { ...state.areas[state.activeTab].customized, qty: false }
      };
    },

    /**
     * Sets the rack fill for the current area.
     * @param {Object} state - The current state.
     * @param {Object} action - The action object.
     * @param {Object} action.payload - The new rack fill data.
     */
    setRackFill: (state, action) => {
      if (!(state.activeTab in state.areas)) return;
      if (action.payload) state.areas[state.activeTab].rackFill = action.payload
    },

    /**
     * Sets the recalculate flag for one or all areas.
     * @param {Object} state - The current state.
     * @param {Object} action - The action object.
     * @param {Object} action.payload - The recalculate information.
     * @param {string} [action.payload.area] - The ID of the area to update, or 'current' for the active area.
     * @param {boolean} action.payload.flag - The new recalculate flag value.
     */
    setRecalculate: (state, action) => {
      const { area = null, flag } = action.payload
      if (area) {
        state.areas[area === 'current' ? state.activeTab : area].recalculate = flag;
      } else {
        values(state.areas).forEach(area => area.recalculate = flag);
      }
    },

    /**
     * Sets the remap flag for the current area.
     * @param {Object} state - The current state.
     * @param {Object} action - The action object.
     * @param {boolean} action.payload - The new remap flag value.
     */
    setRemap: (state, action) => {
      if (!(state.activeTab in state.areas)) return;
      state.areas[state.activeTab].remap = action.payload;
      if (action.payload) {
        const spkr = state.areas[state.activeTab].speakerModel;
        state.areas[state.activeTab].speakers.forEach(speaker => speaker.modelName = spkr);
      }
    },

    /**
     * Sets customized properties for the current area.
     * @param {Object} state - The current state.
     * @param {Object} action - The action object.
     * @param {Object} [action.payload] - The customized properties to set.
     */
    setCustomized: (state, action) => {
      if (!(state.activeTab in state.areas)) return;
      if (!isEmpty(action.payload)) {
        toPairs(action.payload).forEach(([path, value]) => {
          set(state.areas[state.activeTab].customized, path.split('.')[0], true); // set the customized flag
          set(state.areas[state.activeTab], path, value); // set the value in the store
        });
      } else {
        toolIndex[state.currentTool].resetArea(state, null, state.currentDevice);
      };
    },

    /**
     * Sets the speaker layout for multiple areas.
     * @param {Object} state - The current state.
     * @param {Object} action - The action object.
     * @param {Array} action.payload - An array of area objects with updated speaker layouts.
     */
    setSpeakerLayout: (state, action) => {
      action.payload.forEach(area => {
        area = camelizeObject(area);
        const id = area.id;
        if (!(state.areas[id].recalculate || state.areas[id].remap)) return;
        const remap = state.areas[id].remap;
        state.areas[id] = {
          ...state.areas[id],
          ...omit(area, [...['apiVersion', 'apiMessage'],
          ])
        };
        state.areas[id].design = state.areas[id].design || []
        state.areas[id].speakers = state.areas[id].speakers || []
        if (!remap) state.areas[id].speakerModel = area.speakers?.[0].modelName;
        state.areas[id].speakerLayout = state.areas[id].design.map((sub, index) => {
          const regex = /\s(?<type>[a-z]+)(?<aisleIndex>\d+)/g;
          const { aisleIndex = null } = regex.exec(sub.id)?.groups || {};
          let bbox;
          if (aisleIndex) {
            const { left, top } = state.areas[id].location;
            bbox = state.areas[id].aislesArray[aisleIndex];
            bbox = toPairs(bbox).reduce((acc, [key, value]) =>
              ({ ...acc, [key]: value + (['left', 'length'].includes(key) ? left : top) }), {});
          } else {
            bbox = { ...state.areas[id].location, ...state.areas[id].dimensions };
          };
          return ({ bbox: bbox, ...sub })
        });
        state.currentDevice = 'speakers';
        state.areas[id].recalculate = false;
        state.areas[id].remap = false;
      });
    },

    /** 
     * Append room slice and classroom slice
     */
    ...roomSlice.reducers,
    ...classroomSlice.reducers,



  },

  // when a ui reducder is called i.e. setTool, this builder catches it abd does something locally 
  extraReducers: (builder) => {
    builder
      .addCase(uiReducers.setTool, (state, action) => {
        const tool = action.payload;
        // console.log("tool: ", tool);
        const noContainer = !toolIndex[tool].constants.tool.containerName;
        if (state.currentTool !== tool) { // did this to prevent double trigger
          state.currentTool = tool;
          if (noContainer) {
            // todo: need to find a way to set initial state to container on load
            state.name = null
            state.dimensions = null
            state.loadingDocks = null
            state.travelLane = null
            state.ghostDocks = null
            state.roofType = null
            state.roofSlopeOrientation = null
            state.roofHeight = null
            state.roofGrid = null
            state.roofGridSpacing = null
            state.roofGridOffset = null


            containerSlice.caseReducers.addArea(state)


          };
        };
      })

      .addCase(uiReducers.setActiveTab, (state, action) => {
        state.activeTab = action.payload;
      })

      .addCase(uiReducers.setCurrentView, (state, action) => {
        const { devices = [], hideDevices = [] } = toolIndex[state.currentTool].constants;
        const currentDevice = devices.includes(action.payload) ? action.payload : hideDevices.includes(action.payload) ? null : state.currentDevice;
        state.currentDevice = currentDevice; 
      })

  },
});

export const containerReducers = { ...containerSlice.actions };
export default containerSlice.reducer;

// Container Selectors
/**
 * Selects the active tab from the state.
 * @param {Object} state - The Redux state.
 * @returns {string} The active tab ID.
 */
export const getActiveTab = state => state.container.activeTab;

/**
 * Selects the list of area IDs from the state.
 * @param {Object} state - The Redux state.
 * @returns {string[]} An array of area IDs.
 */
export const getAreaList = state => keys(state.container.areas);

/**
 * Selects the current area ID if it exists in the area list.
 * @param {Object} state - The Redux state.
 * @returns {string|null} The current area ID or null if not found.
 */
export const getArea = createSelector(getAreaList, getActiveTab, (areas, tab) => includes(areas, tab) ? tab : null);

/**
 * Checks if any area needs recalculation.
 * @param {Object} state - The Redux state.
 * @returns {boolean} True if any area needs recalculation, false otherwise.
 */
export const getRecalculate = state => values(state?.container?.areas ?? {}).some(area => area.recalculate);

/**
 * Checks if any area needs remapping.
 * @param {Object} state - The Redux state.
 * @returns {boolean} True if any area needs remapping, false otherwise.
 */
export const getRemap = state => values(state?.container?.areas ?? {}).some(area => area.remap);

/**
 * Selects the available area for adding a new area.
 * @param {Object} state - The Redux state.
 * @returns {Object|null} The available area object or null if no space is available.
 *                        The object has the structure: 
 *                        { location: { left: number, top: number },
 *                          dimensions: { length: number, width: number } }
 */
export const getAvailableArea = createSelector(
  container => getRectangle({ ...container.dimensions }),
  container => values(container.areas).map(area => getRectangle({ ...area.location, ...area.dimensions })),
  container => toolIndex[container.currentTool].constants.area.min,
  (outer, areaList, minDimension) => {
    const coords = outer.concat(areaList).flat();
    const xCords = [...new Set(coords.filter((_, i) => i % 2 === 0))].sort((a, b) => a - b);
    const yCords = [...new Set(coords.filter((_, i) => i % 2 !== 0))].sort((a, b) => a - b);
    const matrix = yCords.slice(0, -1).map((y, i) => xCords.slice(0, -1).map((x, j) => {
      // filter out small areas 
      if (xCords[j + 1] - x <= minDimension || yCords[i + 1] - y <= minDimension) return 0;
      const testArea = [x, y, xCords[j + 1], yCords[i + 1]];
      return !some(areaList, (area) => isInside(testArea, area)) ? getSurface(testArea) : 0;
    }));
    const indexes = findLargest(matrix);
    if (some(indexes)) {
      return ({
        location: { left: parseDistance(xCords[indexes[0]]), top: parseDistance(yCords[indexes[1]]) },
        dimensions: { length: parseDistance(xCords[indexes[2]] - xCords[indexes[0]]), width: parseDistance(yCords[indexes[3]] - yCords[indexes[1]]) }
      })
    } else {
      return null
    };
  }
);

/**
 * Selects the state of a specific area.
 * @param {Object} state - The Redux state.
 * @param {string} areaId - The ID of the area to select.
 * @returns {Object|undefined} The state of the specified area, including properties like
 *                             dimensions, location, ceilingHeight, table, acoustics, etc.
 */
export const getAreaState = createCachedSelector(
  state => state.container.areas,
  getActiveTab,
  // getArea,
  (areas, areaId) => areas?.[areaId]
)(
  // getArea
  getActiveTab
);

/**
 * The folowing three selectors are older implementaion but still used in the code. 
 * In all places where the below selectors are used, they hould be replaced with the 
 * Three new ones indicated below (same name ending with 2)
 * Any new implementation should use the newer version 
 */

/**
 * DO NOT USE - USE getAreaProperty2 below
 * Selects a specific property from an area's state.
 * @param {Object} state - The Redux state.
 * @param {string} property - The property to select from the area state.
 * @returns {*} The value of the specified property from the area state.
 */
export const getAreaProperty = createCachedSelector(
  getAreaState,
  (state, property) => property,
  (areaState, property) => get(areaState, getStoreProp(property))
  // return areaState?.[getStoreProp(property)
)(
  (state, property) => property
);

/**
 * DO NOT USE - USE getContainerProperty2 below
 * Selects a specific property from the container state (excluding areas).
 * @param {Object} state - The Redux state.
 * @param {string} property - The property to select from the container state.
 * @returns {*} The value of the specified property from the container state.
 */
const getContainerProperty = createCachedSelector(
  state => omit(state.container, 'areas'),
  (state, property) => property,
  (containerState, property) => get(containerState, getStoreProp(property))
  // containerState?.[getStoreProp(property)]
)(
  (state, property) => property
);

/**
 * DO NOT USE - USE getCurrfentValue2 below
 * Selects the current value of a property, checking area, container, and UI states.
 * @param {Object} state - The Redux state.
 * @param {string} property - The property to select.
 * @returns {*} The current value of the specified property.
 */
export const getCurrentValue = createCachedSelector(
  getAreaProperty,
  getContainerProperty,
  getUIProperty,
  (state, property) => property,
  (areaValue, containerValue, uiValue) => areaValue ?? containerValue ?? uiValue ?? null
)(
  (state, property) => property
);

/**
 * REPLACES getAreaProperty above
 * Selects a nested property from an area's state.
 * @param {Object} state - The Redux state.
 * @param {...string} args - The nested property path to select.
 * @returns {*} The value of the specified nested property from the area state.
 */
export const getAreaProperty2 = createCachedSelector(
  getAreaState,
  (state, ...args) => isString(args) ? args : remove(args, (x) => x !== undefined).join('.'),
  (areaState, property) => get(areaState, getStoreProp(property))
)(
  (state, ...args) => isString(args) ? args : remove(args, (x) => x !== undefined).join(':')
);

/**
 * REPLACES getContsasinerProperty above
 * Selects a nested property from the container state (excluding areas).
 * @param {Object} state - The Redux state.
 * @param {...string} args - The nested property path to select.
 * @returns {*} The value of the specified nested property from the container state.
 */
const getContainerProperty2 = createCachedSelector(
  state => omit(state.container, 'areas'),
  (state, ...args) => isString(args) ? args : remove(args, (x) => x !== undefined).join('.'),
  (containerState, property) => get(containerState, getStoreProp(property))
)(
  (state, ...args) => isString(args) ? args : remove(args, (x) => x !== undefined).join(':')
);

/**
 * REPLACES getCurrentValue above
 * Selects the current value of a nested property, checking area, container, and UI states.
 * @param {Object} state - The Redux state.
 * @param {...string} args - The nested property path to select.
 * @returns {*} The current value of the specified nested property.
 */
export const getCurrentValue2 = createCachedSelector(
  getAreaProperty2,
  getContainerProperty2,
  getUIProperty,
  (state, ...args) => {
    // console.log("args: ", args, isString(args) ? args : remove(args, (x) => x !== undefined).join('.')  )//].join('.'));
    return isString(args) ? args : remove(args, (x) => x !== undefined).join('.')
  },
  (areaValue, containerValue, uiValue) => areaValue ?? containerValue ?? uiValue ?? null
)(
  (state, ...args) => isString(args) ? args : remove(args, (x) => x !== undefined).join(':')
);

/**
 * Evaluates a condition based on specified parameters and a check function.
 * @param {Object} state - The Redux state.
 * @param {Object} args - The arguments object.
 * @param {string[]} [args.parameter=[]] - The parameters to check.
 * @param {Function} [args.check=() => true] - The check function.
 * @param {string|null} [args.area=null] - The area to check, if applicable.
 * @returns {boolean} The result of the condition check.
 */
export const getConditionResult = createCachedSelector(
  state => state,
  (state, args) => args,
  (state, args) => {
    const { parameter = [], check = () => true, area = null } = args;
    const parameters = parameter.map(val => getCurrentValue(state, val));
    return check([...parameters, area])
  }
)(
  (state, args) => {
    const { parameter = [], area = 0 } = args
    return `${parameter.join(':')}:${area}`
  }
);

/**
 * Selects available actions based on specified conditions.
 * @param {Object} state - The Redux state.
 * @param {Object} args - The arguments object.
 * @param {string[]} [args.items=[]] - The list of potential actions.
 * @param {Object} [args.conditions={}] - The conditions for each action.
 * @returns {string[]} The list of available actions.
 */
export const getAvailableActions = createCachedSelector(
  state => state,
  (state, args) => args,
  (state, args) => {
    const { items = [], conditions = {} } = args;
    return items.filter(item => has(conditions, item) ? getConditionResult(state, conditions[item]) : true)
  }
)(
  // getArea
  (state, args) => { return args.area || '0' },
);

/**
 * Selects the list of devices of a specific type.
 * @param {Object} state - The Redux state.
 * @param {string} device - The type of device to select (e.g., 'speakers', 'microphones').
 * @returns {Object[]} The list of devices of the specified type.
 */
export const getDeviceList = createCachedSelector(
  state => state,
  (state, device) => device,
  (state, device) => {
    const deviceList = getArea(state) ? getCurrentValue(state, device) : values(state.container.areas).flatMap(area => area[device]);
    return deviceList
  }
)(
  (state, device) => {
    return `${device}:${getArea(state)}`
  }
);


// marker these are not eficient yet. When something changes, say name, the whole thing gets recalculated

// fixme: This selector retrieves the entire areas array which contains a lot of data. This should be replaced wherever it;s used by a better selector
/**
 * Gets the areas array.
 * @param {Object} state - The Redux state.
 * @returns {Object|Object[]} An array of all areas.
 */
export const getAreas = state => state.container.areas;

// // fixme: This selector retrieves the entir container (including areas) which contains a lot of data. This should be replaced wherever it;s used by a better selector
/**
 * Selects the current area ID if it exists in the area list.
 * @param {Object} state - The Redux state.
 * @returns {string|null} The current area ID or null if not found.
 */
const getContainer = state => state.container

/**
 * Selects all areas or a specific area based on the active tab.
 * @param {Object} state - The Redux state.
 * @returns {Object|Object[]} Either an array of all areas or a single area object.
 */
export const getAreaArray = createSelector(
  getActiveTab,
  getAreas,
  (tab, areas) => {
    return tab in areas ? [areas[tab]] : values(areas)
  }
);

/**
 * Selects the outer box dimensions based on zoom state and active tab.
 * @param {Object} state - The Redux state.
 * @returns {Object} The outer box dimensions object with length and width properties.
 */
export const getOuterBox = createSelector(
  getZoom,
  getActiveTab,
  getAreas,
  getContainer,
  (isZoomed, tab, areas, container) => {
    return (isZoomed ? areas[tab] : container).dimensions
  }
);

/**
 * Selector that computes the bounding box and center for one or multiple areas.
 * 
 * @function
 * @name getAreaBoxes
 * @param {Object} state - The Redux state.
 * @returns {Array} An array of objects where each object represents a single area's bounding box and center
 *  . Each object contains:
 *   @property {string} id - The unique identifier of the area.
 *   @property {number} top - The top position of the area.
 *   @property {number} left - The left position of the area.
 *   @property {number} length - The length of the area.
 *   @property {number} width - The width of the area.
 *   @property {number} cx - The x-coordinate of the area's center.
 *   @property {number} cy - The y-coordinate of the area's center.
 * 
 * @description
 * This selector processes the area data from the state and calculates the bounding box
 * and center coordinates for each area. It returns an array of objects.
 * 
 * @example
 * const areaBoxes = useSelector(getAreaBoxes);
 * areaBoxes.forEach(box => console.log(box.id, box.cx, box.cy));
 */
export const getAreaBoxes = createSelector(
  getAreaArray,
  (areas) => {
    return areas.map(area => {
      const { id, location, dimensions } = area
      const center = { cx: location.left + dimensions.length / 2, cy: location.top + dimensions.width / 2 };
      return { id, ...location, ...dimensions, ...center }
    })
  }
)

/**
 * @function getPlotProps
 * @description Selector that computes plot properties based on the current device, plot type, area data, and units.
 * 
 * @type {import('reselect').OutputSelector<RootState, PlotProps, (res1: Area[], res2: string, res3: string, res4: string) => PlotProps>}
 * 
 * @param {Area[]} areaArray - Array of areas in the current view
 * @param {string} currentDevice - The currently selected device type
 * @param {string} currentPlot - The currently selected plot type
 * @param {string} currentUnits - The currently selected units (feet or meters)
 * 
 * @returns {PlotProps} An object containing computed plot properties
 * 
 * @property {number} minLevel - Computed minimum level for the plot
 * @property {number} maxLevel - Computed maximum level for the plot
 * @property {Object} labels - Computed labels for the plot legend
 * @property {string} units - Units for the plot (may be distance units based on currentUnits)
 * @property {number} target - Computed target level (given or weighted average of min and max)
 * @property {number|null} average - Computed average value across all areas, or null if not applicable
 * @property {number|null} deviation - Computed standard deviation, or null if not applicable
 * @property {boolean} error - Indicates if there was an error in computations
 * 
 * @throws {Error} Implicitly throws if required data is missing or invalid
 * 
 * @example
 * const plotProps = useSelector(getPlotProps);
 * console.log(plotProps.minLevel, plotProps.maxLevel, plotProps.units);
 * 
 * @note
 * This selector dynamically computes plot properties based on the current device and plot type.
 * It handles unit conversions and generates appropriate labels for distance-based plots.
 * The selector will return an empty object if the current device or plot is not set.
 */
export const getPlotProps = createSelector(
  getAreaArray,
  state => getCurrentValue2(state, 'currentDevice'),
  state => getCurrentValue2(state, 'showMap'),
  state => getCurrentValue2(state, 'units'),
  (areaArray, currentDevice, currentPlot, currentUnits) => {
    // fixme: these are temporary saffety stops to prevent unset data from causing errors
    if (!currentDevice || !currentPlot) return {}
    if (!devicePlots[currentDevice].plots[currentPlot]) return {}

    const plotProperties = devicePlots[currentDevice].plots[currentPlot];
    const { minLevel: min, maxLevel: max, average: avg, deviation: std, roundTo = 1, target: tgt, units: legendUnits, labels: legendLabels } = plotProperties;
    const minLevel = isFinite(min) ? min : Math.min(...areaArray.map(obj => get(obj, min)).filter(Boolean));
    const maxLevel = isFinite(max) ? max : Math.max(...areaArray.map(obj => get(obj, max)).filter(Boolean));
    const useDistanceUnits = !legendUnits && !legendLabels;
    let labels = legendLabels;
    let units = legendUnits
    if (useDistanceUnits) {
      units = capitalize(currentUnits)
      const isMeter = currentUnits === 'meters';
      const step = isMeter ? 1 : 3
      const distanceArray = Array.from({ length: Math.ceil(toFeet(maxLevel, isMeter) / step) }, (_, i) => i * step).slice(1);
      labels = distanceArray.reduce((acc, curr) => ({...acc, [toString(curr)]: toMeter(curr, isMeter) }), {})
    };
    const target = tgt ? Math.min(...areaArray.map(obj => get(obj, tgt)).filter(Boolean)) : minLevel * .35 + maxLevel * .65;
    let average = areaArray.map(obj => obj[avg]).filter(Boolean).reduce((avg, value, _, { length }) => avg + value / length, 0);
    average = average === 0 ? null : round(average, roundTo);
    let deviation = round(areaArray.map(obj => obj[std]).reduce((avg, value, _, { length }) => avg + value / length, 0), 2);
    deviation = isFinite(deviation) ? deviation : null;
    const error = !isFinite(minLevel) || !isFinite(maxLevel) || !isFinite(average)
    return {
      ...plotProperties,
      minLevel,
      maxLevel,
      labels,
      units,
      target,
      average,
      deviation,
      error
    }
  }
);

/**
 * Selector that generates plot data for different types of visualizations in Master Designer.
 * 
 * @function
 * @name getPlotData
 * @param {Object} state - The Redux state.
 * @returns {Object} An object containing:
 *   @property {boolean} ready - Indicates if the plot data is ready to be used.
 *   @property {Array} areaArray - An array of objects, each representing plot data for an area.
 * 
 * @description
 * This selector combines plot properties, area boxes, and area data to generate
 * plot data for visualization. It currently supports SPL (Sound Pressure Level), 
 * STI (Speech Transmission Index), and microphone distance plots, and is designed 
 * to be extensible for additional plot types.
 * 
 * The selector performs the following steps:
 * 1. Extracts plot type, source, and error status from plot properties.
 * 2. Processes area data based on the plot type:
 *    - For SPL and STI: Combines speaker layout data with area subdivision information.
 *    - For micDistance: Uses microphone layout data.
 * 3. Merges processed data with area box information.
 * 4. Returns an object indicating readiness and the processed area array.
 * 
 * Each object in the returned areaArray contains:
 * - Area location and dimension data
 * - An array of combined data objects, each including:
 *   - Subdivision information (if applicable)
 *   - Plot-specific data (e.g., receiver spacing, offsets, and map array)
 * 
 * @example
 * const plotData = useSelector(getPlotData);
 * if (plotData.ready) {
 *   plotData.areaArray.forEach(area => {
 *     // Render plot for each area
 *   });
 * }
 * 
 * @note
 * This selector is designed to be extended for additional plot types in the future.
 * When adding new plot types, ensure to update the switch statement in the selector.
 */
export const getPlotData = createSelector(
  getPlotProps,
  getAreaBoxes,
  getAreaArray,

  (plotProps, areaBoxes, areas) => {
    const { plotType, source, error } = plotProps;
    // Create a Map to store merged objects by id
    const mergedMap = new Map();
    // Get  the required data depending on plot type
    switch (plotType) {
      case 'SPL':
      case 'STI':
        areas.forEach(area => {
          if (!area.speakerLayout) return;
          const combinedArray = [];
          area.speakerLayout.forEach((layout, index) => {
            const id = layout.id;
            const regex = /\s(?<type>[a-z]+)(?<aisleIndex>\d+)/g;
            const { type = null, aisleIndex = null } = regex.exec(id)?.groups || {};
            const subdivision = (type && aisleIndex) ? area.aislesArray[+aisleIndex] : null;
            const data = {
              id: layout.id,
              receiverSpacing: layout.receiverSpacing,
              receiverXOffset: layout.receiverXOffset,
              receiverYOffset: layout.receiverYOffset,
              mapArray: layout[source]
            };
            combinedArray.push({ subdivision, data });
          })
          mergedMap.set(area.id, { areaData: combinedArray });
        });
        break;
      case 'micDistance':
        areas.forEach(area => {
          if (!area.microphones.layout || area.microphones.layout.length === 0) return
          const data = {
            id: area.id,
            mapArray: area.microphones.layout
          }
          mergedMap.set(area.id, { areaData: [{ data }] });
        });
        break
            // Add other plot types here 
      default:
        break;
    }
    // merge areaBoxes to create a single object for each heatmap area
    areaBoxes.forEach(box => {
      if (mergedMap.has(box.id)) {
        mergedMap.set(box.id, { ...mergedMap.get(box.id), ...box, id: `${box.id}-heatmap` })
      } else {
        mergedMap.set(box.id, { ...box, id: `${box.id}-heatmap` })
      };
    });

    return {
      ready: Boolean(plotType) && !error,
      areaArray: Array.from(mergedMap.values())
    }
  }
)

