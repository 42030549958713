import dialogs from "globalConstants/dialogs";
import { round, values } from "lodash";
import constants from "toolConstants/warehouseDesigner/constants";

const modals = {
  ...dialogs,
  launch: {
    image: 'images/Warehouse_Designer_splash.jpg',
    title: 'Welcome to the Biamp Warehouse Designer!',
    intro: `You will be guided through a series of steps to design your warehouse, add functional areas, define each area's usage, coverage, and design goals,
      and get a customized recommendation of models, quantities, and placement plan for Biamp speakers.`,
    button: 'BEGIN WAREHOUSE DESIGN',
    target: 'warehouse'
  },

  // marker can this be moved to dialogs as well? need to figure how to add the dynamic content. In this case, the footer label 
  tutorial: {
    theme: 'tutorial',
    header: 'TUTORIAL',
    body: [
      {
        element: 'video',
        id: 'wd-tutorial',
        extend: true
      }
    ],
    footer: [
      {},

      {
        condition: {
          parameter: ['isFirstTimeUser'],
          check: ([first]) => first ? 'hide' : 'show'
        },
        content: {
          hide: {},
          show: {
            label: 'RETURN TO WAREHOUSE >',
            target: 'empty',
            // dispatcher: { name: 'setIsFirstTimeUser', value: false }
          },
        },


        // label: 'RETURN TO WAREHOUSE >',
        // target: 'empty',
        // dispatcher: {name: 'setIsFirstTimeUser', value: false}
      }
    ]
  },

  // Marker can this modal be moved to dialogs? It's the same for all with the exeption of the ok button
  editProject: {
    dispatcher: 'setProject',
    header: 'EDIT PROJECT DETAILS',
    body: [
      {
        element: 'textInput',
        name: 'name',
        label: 'Project Name'
      },
      {
        element: 'textInput',
        name: 'company',
        label: 'Company Name'
      },
      {
        element: 'textInput',
        name: 'designer',
        label: 'Design engineer'
      },
    ],
    footer: [
      {},
      {
        label: 'RETURN TO WAREHOUSE >',
        target: ''
      }
    ]

  },

  warehouse: {
    menu: 'floorplan',
    header: 'DESIGN YOUR WAREHOUSE',
    body: [
      {
        element: 'textInput',
        name: 'name',
        label: 'Warehouse Name',
        dispatcher: 'setName'
      },
      {
        element: 'dimensionInput',
        label: 'Warehouse Dimensions',
        ids: ['length', 'width'],
        dispatcher: 'setDimensions',
        errorCode: 1000,
      },
      {
        // condition: { parameter: ['dimensions'], check: ([dimensions]) => Math.min(dimensions.length, dimensions.width) >=  14.6304},
        element: 'selectButtons',
        label: 'Loading Docks Location',
        items: ['left_docks', 'top_docks', 'right_docks', 'bottom_docks'],
        dispatcher: 'setLoadingDocks',
        errorCode: 1010,

      },
      {
        condition: { parameter: ['loadingDocks'], check: ([docks]) => values(docks || {}).some(dock => dock) },
        className: 'ms-2',
        element: 'dimensionInput',
        label: 'Travel Lane Width',
        ids: ['width'],
        dispatcher: 'setTravelLane',
        errorCode: 1020,
      },
      {
        element: 'unitSwitch'
      }
    ],
    footer: [
      {},
      {
        label: 'CONTINUE TO DEFINE WAREHOUSE ROOF >',
        target: 'roof',
        dispatcher: { name: 'modalForward', value: false }
      }
    ]
  },

  roof: {
    menu: 'roof',
    header: 'DESIGN YOUR WAREHOUSE ROOF',
    body: [
      {
        element: 'toggleButtons',
        label: 'Roof Type',
        items: ['aframe', 'slope', 'flat'],
        dispatcher: 'setRoofType'
      },
      {
        condition: { parameter: ['roofType'], check: ([type]) => type === 'aframe' },
        className: 'ms-2',
        element: 'toggleButtons',
        label: 'Roof Slope Direction',
        items: ['vertical', 'horizontal'],
        dispatcher: 'setRoofSlopeOrientation'
      },
      {
        condition: { parameter: ['roofType'], check: ([type]) => type === 'slope' },
        className: 'ms-2',
        element: 'toggleButtons',
        label: 'Roof Slope',
        items: ['slopeRight', 'slopeBottom', 'slopeLeft', 'slopeTop'],
        dispatcher: 'setRoofSlopeOrientation'
      },
      {
        condition: { parameter: ['roofType'], check: ([type]) => type === 'flat' },
        element: 'dimensionInput',
        label: 'Roof Height',
        ids: ['min'],
        dispatcher: 'setRoofHeight',
        errorCode: 1100,
      },
      {
        condition: { parameter: ['roofType'], check: ([type]) => type !== 'flat' },
        element: 'rangeInput',
        label: 'Roof Height Range',
        ids: ['min', 'max'],
        dispatcher: 'setRoofHeight',
        errorCode: 1100,
      },
      {
        element: 'slider',
        label: 'Use Roof Grid',
        dispatcher: 'setRoofGrid'
      },
      {
        condition: { parameter: ['roofGrid'], check: ([grid]) => grid },
        className: 'ms-2',
        element: 'dimensionInput',
        label: 'Roof Grid Spacing',
        ids: ['x', 'y'],
        dispatcher: 'setRoofGridSpacing',
        errorCode: 1110,
      },
      {
        condition: { parameter: ['roofGrid'], check: ([grid]) => grid },
        className: 'ms-2',
        element: 'positionInput',
        label: 'Roof Grid Offset',
        ids: ['dx', 'dy'],
        dispatcher: 'setRoofGridOffset',
      },
      {
        element: 'unitSwitch'
      }
    ],
    footer: [
      {
        // color: 'var(--app-medium-gray)',
        // label: '< BACK',
        // target: 'warehouse',
        // dispatcher: { name: 'modalForward', value: false }
      },
      {
        condition: { parameter: ['tabs', 'activeTab'], check: ([tabs, activeTab]) => tabs.at(-1).id === activeTab ? 'add' : 'next' },
        content: {
          add: {
            label: 'CONTINUE TO DEFINE AREA >',
            target: 'area',
            dispatcher: { name: 'modalForward', value: true }
          },
          next: {
            label: 'CONTINUE TO AREA >',
            target: 'area',
            dispatcher: { name: 'modalForward', value: true }
          }
        }
      }
    ]
  },

  area: {
    menu: 'floorplan',
    header: 'DEFINE YOUR AREA',
    body: [
      {
        element: 'textInput',
        name: 'name',
        label: 'Area Name',
        dispatcher: 'setName'
      },
      {
        element: 'dimensionInput',
        label: 'Area Dimensions',
        ids: ['length', 'width'],
        dispatcher: 'setDimensions',
        errorCode: 1200,
      },
      {
        condition: { parameter: ['zoom'], check: ([zoom]) => !zoom },
        element: 'positionInput',
        label: 'Area Origin',
        ids: ['left', 'top'],
        dispatcher: 'setLocation',
      },
      {
        element: 'slider',
        label: "Open Ceiling",
        dispatcher: 'setOpenCeiling'
      },
      {
        condition: { parameter: ['openCeiling', 'roofGrid'], check: ([open, grid]) => open && grid },
        element: 'slider',
        label: "Snap Speakers to Roof Grid",
        dispatcher: 'setSnapToGrid'
      },
      {
        condition: { parameter: ['openCeiling'], check: ([open]) => !open },
        element: 'dimensionInput',
        label: 'Closed Ceiling Height',
        ids: ['min'],
        dispatcher: 'setCeilingHeight',
        errorCode: 1210
      },
      {
        element: 'rangeInput',
        label: 'Mounting Height Range',
        ids: ['min', 'max'],
        dispatcher: 'setMountingHeight',
        errorCode: 1220
      },
      {
        condition: { parameter: ['activeTab'], check: ([tab]) => !tab.includes('docks') },
        element: 'selectButtons',
        label: 'Surrounding Walls',
        items: ['leftwall', 'topwall', 'rightwall', 'bottomwall'],
        dispatcher: 'setSurroundingWalls'
      },
      {
        element: 'unitSwitch'
      }
    ],
    footer: [
      {
        // color: 'var(--app-medium-gray)',
        // // todo: use the condition to jump over the dock areas when navigating backwards 
        // condition: { parameter: ['tabs', 'activeTab', 'exitToRoom'], check: ([tabs, activeTab, exit]) => exit ? 'exit' : tabs.findIndex(tab => tab.id === activeTab) <= 1 ? 'container' : 'area' },
        // content: {
        //   container: {
        //     label: '< BACK',
        //     target: 'roof',
        //     dispatcher: { name: 'modalForward', value: false }
        //   },
        //   area: {
        //     label: '< BACK',
        //     target: 'areaDesignPriority',
        //     dispatcher: { name: 'modalForward', value: false }
        //   },
        //   exit: {}
        // }
      },
      {
        label: 'CONTINUE TO DEFINE AREA USAGE >',
        target: 'areaUsage',
        dispatcher: { name: 'setStorageReady', value: true }
      }
    ]
  },

  areaUsage: {
    menu: 'areausage',
    header: 'DEFINE YOUR AREA USAGE',
    body: [
      {
        condition: { parameter: ['activeTab'], check: ([tab]) => !tab.includes('docks') },
        element: 'toggleButtons',
        label: 'Usage Type',
        items: ['palletRack', 'automatedHandling', 'manualHandling', 'pallet', 'trafficArea'],
        tooltips: constants.area.tooltips,
        dispatcher: 'setAreaUsage',
        errorCode: 1300
      },
      {
        condition: { parameter: ['activeTab'], check: ([tab]) => tab.includes('docks') },
        element: 'toggleButtons',
        label: 'Usage Type',
        items: ['trafficArea'],
        tooltips: constants.area.tooltips,
        dispatcher: 'setAreaUsage',
      },
      {
        condition: { parameter: ['areaUsage'], check: ([usage]) => usage === 'palletRack' },
        element: 'selector',
        className: 'ms-2',
        label: 'Upright height',
        placeHolder: 'Select Height',
        items: [8, 10, 12, 14, 16, 18, 20, 22, 24, 26, 28, 30, 32, 34, 36],
        filter: { parameter: 'setMountingHeight', filter: (height, items) => items.filter(x => round(x * 0.3048, 4) <= (height?.min ?? 36)) },
        unit: "'",
        dispatcher: 'setUprightHeightFt',
      },
      {
        condition: { parameter: ['areaUsage'], check: ([usage]) => usage === 'palletRack' },
        element: 'toggleButtons',
        label: 'Orientation',
        items: ['horizontal', 'vertical'],
        dispatcher: 'setStorageOrientation'
      },
      {
        condition: { parameter: ['areaUsage'], check: ([usage]) => !['pallet', 'automatedHandling', 'manualHandling', 'trafficArea'].includes(usage) },
        element: 'numberInput',
        label: 'Number of Picking Aisles',
        dispatcher: 'setPickingAisles',
        errorCode: 1320
      },
      {
        condition: { parameter: ['areaUsage'], check: ([usage]) => !['pallet', 'automatedHandling', 'manualHandling', 'trafficArea'].includes(usage) },
        element: 'numberInput',
        label: 'Number of Primary Aisles',
        dispatcher: 'setPrimaryAisles',
        errorCode: 1330,
      },
      // {
      //   condition: {parameter: ['areaUsage'], check: ([usage]) => !['pallet', 'automatedHandling', 'manualHandling', 'trafficArea'].includes(usage)},
      //   element: 'numberInput',
      //   label: 'Number of Connector Aisles',
      //   dispatcher: 'setConnectorAisles'
      // },
      {
        element: 'unitSwitch'
      }
    ],
    footer: [
      {
        // label: '< BACK',
        // color: 'var(--app-medium-gray)',
        // target: 'area',
      },
      {
        label: 'CONTINUE TO DEFINE AREA DESIGN PRIORITY >',
        target: 'areaDesignPriority',
        dispatcher: { name: 'setAllowAddArea' },
      }
    ]
  },

  areaDesignPriority: {
    menu: 'designpriority',
    header: 'DEFINE AREA DESIGN PRIORITY',
    body: [
      {
        element: 'toggleButtons',
        label: 'Background Noise',
        items: ['noise_low', 'noise_medium', 'noise_high', 'noise_extreme'],
        dispatcher: 'setBackgroundNoise'
      },
      {
        element: 'measurementInput',
        spinStep: 3,
        className: 'ms-2',
        unit: 'dBA',
        label: 'Noise Level',
        dispatcher: 'setNoiseLevel',
        errorCode: 1400
      },
      {
        element: 'toggleSelect',
        className: 'd-table',
        label: 'Coverage',
        labels: true,
        items: {
          palletRack: ['picking_aisles', 'primary_aisles', 'general', 'none'], // 'connector_aisles',
          automatedHandling: ['general', 'none'],
          manualHandling: ['general', 'none'],
          pallet: ['general', 'none'],
          trafficArea: ['general', 'none']
        },
        keyCondition: { parameter: ['areaUsage'], check: ([usage]) => usage },
        toggle: ['picking_aisles', 'primary_aisles', 'general', 'none'],
        noSelect: 'none',
        theme: 'buttonGroupBig',
        activeState: 'coverage_active',
        inactiveState: "coverage_inactive",
        dispatcher: 'setCoverage'
      },
      {
        condition: { parameter: ['showing', 'coverage'], check: ([showing, coverage]) => showing === 'area' && !coverage.none },
        element: 'toggleButtons',
        label: 'System Primary Usage',
        items: ['paging_bgm', 'paging'],
        tooltips: {
          paging_bgm: 'Paging and Background Music',
          paging: 'Paging Only'
        },
        dispatcher: 'setSystemPrimaryUsage'
      },
      {
        element: 'unitSwitch'
      },
      {
        condition: { parameter: ['allowAddArea', 'exitToRoom'], check: ([allow, exit]) => allow && !exit },
        show: true,
        element: 'slider',
        className: 'justify-content-end',
        label: 'Add Another Area',
        dispatcher: 'setAddAnotherArea'
      }
    ],
    footer: [
      {
        // label: '< BACK',
        // color: 'var(--app-medium-gray)',
        // target: 'areaUsage',
      },
      {
        condition: {
          parameter: ['tabs', 'activeTab', 'exitToRoom', 'addAnotherArea'],
          check: ([tabs, activeTab, exit, add]) => {
            // console.log("activeTab: ", activeTab);
            // console.log("exit: ", exit);
            // console.log("add: ", add);
            // console.log(tabs.at(-1).id === activeTab ? exit ? 'exit' : add ? 'add' : 'continue' : 'next');

            return tabs.at(-1).id === activeTab ? exit ? 'exit' : add ? 'add' : 'continue' : 'next'
          }
        },
        content: {
          exit: {
            label: 'CONTINUE TO AREA >', //  'CONTINUE TO WAREHOUSE >',
            target: '',
          },
          add: {
            label: 'CONTINUE TO DEFINE NEW AREA >',
            target: 'area',
            dispatcher: { name: 'addArea' },
          },
          continue: {
            // label: 'CONTINUE TO DEFINE PRODUCT FAMILY >',
            // target: 'productFamily',
            label: 'CONTINUE TO WAREHOUSE >',
            target: '',
          },
          next: {
            label: 'CONTINUE TO NEXT AREA >',
            target: 'area',
            dispatcher: { name: 'modalForward', value: true }
          }
        }
      }
    ]
  },

  deleteArea: {
    header: 'DELETE AREA',
    body: [
      {
        element: 'plainText',
        type: 'p',
        content: 'Are you sure you want to permanently delete this area?',
        extend: true 
      }
    ],
    footer: [
      {
        label: 'CANCEL',
        target: '',
        dispatcher: {name: 'setAreaToUpdate', value: null}
      },
      {
        label: 'OK',
        target: 'empty',
        dispatcher: {name: 'deleteArea'}
      }
    ]
  },

  removeDockArea: {
    header: 'REMOVE LOADING DOCKS AREA',
    body: [
      {
        element: 'plainText',
        type: 'p',
        content: 'Would you like to remove the corresponding Loading Docks traffic area?',
        extend: true
      },
      {
        element: 'checkBox',
        className: 'ms-2',
        extend: true,
        dispatcher: 'setModalResponse'
      },
    ],
    footer: [
      {
        condition: { parameter: ['modalResponse'], check: ([del]) => del ? 'yes' : 'no' },
        content: {
          yes: {
            label: 'OK',
            target: '',
            dispatcher: { name: 'deleteArea'}
          },
          no: {
            label: 'OK',
            target: '',
            dispatcher: { name: 'setAreaToUpdate', value: null }
          },
        },
      }
    ]
  },

  addDockArea: {
    header: 'ADD LOADING DOCKS AREA',
    body: [
      {
        element: 'plainText',
        type: 'p',
        content: 'Would you like to add the corresponding Loading Docks traffic area?',
        extend: true
      },
      {
        element: 'plainText',
        type: 'p',
        content: 'Note: This action may require adjusting adjacent areas origin and/or dimensions',
        extend: true,
        size: 16,
        color: 'var(--app-medium-gray)'
      },
      {
        element: 'checkBox',
        className: 'ms-2',
        extend: true,
        dispatcher: 'setModalResponse'
      },
    ],
    footer: [
      {
        condition: { parameter: ['modalResponse'], check: ([del]) => del ? 'yes' : 'no' },
        content: {
          yes: {
            label: 'OK',
            target: '',
            dispatcher: { name: 'addArea', value: { areaToAdd: 'areaToUpdate'} }
          },
          no: {
            label: 'OK',
            target: '',
            dispatcher: { name: 'setAreaToUpdate', value: null }
          },
        },
      }
    ]
  },

  changeSpeaker: {
    header: 'CHANGE RECOMMENDED LOUDSPEAKER MODEL',
    body: [
      {
        element: 'plainText',
        type: 'p',
        content: 'Would you like to lock the current loudspeaker quantity and positions?',
        extend: true
      },
      {
        element: 'checkBox',
        className: 'ms-2',
        extend: true,
        dispatcher: 'setModalResponse'
      },
    ],
    footer: [
      {
        condition: { parameter: ['modalResponse'], check: ([del]) => del ? 'yes' : 'no' },
        content: {
          yes: {
            label: 'OK',
            target: '',
            dispatcher: { name: 'setRemap', value: true }
          },
          no: {
            label: 'OK',
            target: '',
            dispatcher: { name: 'setRecalculate', value: {area: 'current', flag: true} }
          },
        },
      }
    ]
  },
}

export default modals;


