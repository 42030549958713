/**
 * @module DesignContainer
 * @description Main container component for the design view in the Master Designer application.
 * It manages the layout and conditional rendering of various sub-components based on the current application state.
 */
import styled from 'styled-components';
import { useSelector } from "react-redux";
import ActionBar from 'components/organisms/ActionBar';
import SVGContainer from 'components/organisms/SVGContainer';
import HeatMapManager from 'components/services/HeatMapManager';
import Scale from 'components/molecules/Scale';
import EquipmentListManager from 'components/services/EquipmentTableManager';
import Bubble from 'components/atoms/Bubble';
import { getShowMap } from 'reduxModules/ducks/ui';

/**
 * @function DesignContainer
 * @description Renders the main design container with conditional sub-components based on the current view and application state.
 * @returns {React.Component} The rendered DesignContainer component
 */
const DesignContainer = () => {
  // Select relevant state from Redux store
  const currentView = useSelector(state => state.ui.currentView);
  const equipmentList = currentView === 'equipmentlist';

  const showMap = useSelector(getShowMap);


  return (
    <DesignContainer.Wrapper data-tooltip-id="containerTooltip">
      {!equipmentList && <ActionBar />}
      {!equipmentList && <DesignContainer.DynamicWrapper>
        <SVGContainer />
        {showMap && <HeatMapManager />}
      </DesignContainer.DynamicWrapper>}
      {equipmentList && <EquipmentListManager />}
      {showMap && <DesignContainer.ScaleWrapper>
        <Scale />
      </DesignContainer.ScaleWrapper>}
      {!equipmentList && <Bubble id="containerTooltip" place="top" />}
    </DesignContainer.Wrapper>
  )
};

/**
 * Styled component for the main wrapper of DesignContainer.
 * @component
 */
DesignContainer.Wrapper = styled.div.attrs(props => ({
  className: 'col-10 p-0 h-100 d-flex flex-column'
})
)`
`;

/**
 * Styled component for the dynamic content wrapper within DesignContainer.
 * @component
 */
DesignContainer.DynamicWrapper = styled.div.attrs(props => ({
  className: 'position-relative d-flex flex-column h-100 flex-grow-1'
})
)`
`;

/**
 * Styled component for the scale wrapper within DesignContainer.
 * @component
 */
DesignContainer.ScaleWrapper = styled.div.attrs(props => ({
  className: 'd-flex'
})
)`
  min-height: 34px;
`;

export default DesignContainer;