import { createSelector, createSlice } from "@reduxjs/toolkit";
import { createCachedSelector } from 're-reselect';
import toolIndex from "toolConstants";
import { containerReducers, getPlotProps } from "./container";
import devicePlots from "globalConstants/devicePlots";
import { gradients } from "utilities/color";
import { getStoreProp } from "utilities/string";
import { keys, pick, values } from "lodash";


const initialState = {
  tool: '',
  savekey: '',
  revision: '',
  requested: 'latestrevision',
  isLatest: true,
  reload: null,
  units: 'feet',

  // Modals
  modals: [],
  isModalOpen: true,
  modalForward: true,
  modalReopen: '',

  // Tabs
  tabs: [],
  activeTab: '',
  currentView: 'floorplan',
  currentDevice: '',
  zoom: false,
  showing: 'container',

  // Area nav control 
  allowAddArea: true,
  addAnotherArea: true,
  exitToRoom: false,

  // Drawing stuff
  svgBoundingBox: {},
  scale: 1,
  boundingBox: {},
  overlaping: {},

  // Plot definition
  showMap: '',
  showTable: 'summary',

  // mouse action
  action: '',
  bubbleContent: false,

  // Errors
  error: {},
  info: {},
  errorState: false,
  lastError: '',

  // note: temp until release
  apiVersion: null,
  isFirstTimeUser: true,

  modalResponse: null

};

/**
 * UI Slice for managing the application's user interface state.
 * @type {import("@reduxjs/toolkit").Slice}
 */
export const uiSlice = createSlice({
  name: "ui",
  initialState: { ...initialState },
  reducers: {
    /**
     * Sets the current tool.
     * @param {Object} state - The current state.
     * @param {Object} action - The action object.
     * @param {string} action.payload - The tool identifier.
     */
    setTool: (state, action) => {
      state.tool = action.payload;
    },
    /**
     * Sets the save key and revision.
     * @param {Object} state - The current state.
     * @param {Object} action - The action object.
     * @param {Object} action.payload - The params object.
     * @param {string} action.payload.savekey - The save key.
     * @param {string} action.payload.revision - The revision.
     */
    setParams: (state, action) => {
      const { savekey, revision } = action.payload;
      state.savekey = savekey;
      state.revision = revision;
    },
    /**
     * Sets the requested revision.
     * @param {Object} state - The current state.
     * @param {Object} action - The action object.
     * @param {string} action.payload - The requested revision.
     */
    setRequested: (state, action) => {
      state.requested = action.payload;
    },
    /**
     * Sets whether the current revision is the latest.
     * @param {Object} state - The current state.
     * @param {Object} action - The action object.
     * @param {boolean} action.payload - Whether it's the latest revision.
     */
    setIsLatest: (state, action) => {
      state.isLatest = action.payload;
    },
    /**
     * Sets the reload flag based on current state and action.
     * @param {Object} state - The current state.
     * @param {Object} action - The action object.
     * @param {boolean} action.payload - The reload flag.
     */
    setReload: (state, action) => {
      const reloadFlag = action.payload === false;
      state.reload = (!reloadFlag && !state.isLatest && state.requested.includes('latest'));
    },
    /**
     * Toggles between loading latest and current revision.
     * @param {Object} state - The current state.
     */
    setLoadLatest: (state, action) => {
      if (state.isLatest) {
        state.reload = true
        state.isLatest = false
      } else {
        state.reload = false
        state.isLatest = true
      }
    },
    /**
     * Sets the units (feet or meters).
     * @param {Object} state - The current state.
     * @param {Object} action - The action object.
     * @param {string} action.payload - The units ('feet' or 'meters').
     */
    setUnits: (state, action) => {
      state.units = action.payload
    },
    /**
     * Opens a modal.
     * @param {Object} state - The current state.
     * @param {Object} action - The action object.
     * @param {string} action.payload - The modal name to open.
     */
    openModal: (state, action) => {
      const i = state.modals.findIndex(modal => modal.modalName === action.payload);
      i > -1 ? state.modals[i].modalOpen = true : state.modals.push({
        modalName: action.payload,
        modalOpen: true,
      });
      state.isModalOpen = state.modals.some(modal => modal.modalOpen); //true;
    },
    /**
     * Closes a modal or all modals.
     * @param {Object} state - The current state.
     * @param {Object} action - The action object.
     * @param {string} [action.payload] - The modal name to close. If not provided, closes all modals.
     */
    closeModal: (state, action) => {
      const modals = state.modals.map((modal, i) => (!action.payload || modal.modalName === action.payload) ? { ...modal, modalOpen: false } : modal);
      state.modals = modals;
      state.isModalOpen = state.modals.some(modal => modal.modalOpen);
      state.modalResponse = null;
      state.info = {};
    },
    /**
     * Clears all modals.
     * @param {Object} state - The current state.
     */
    clearModals: (state, action) => {
      state.modals = [];
    },
    /**
     * Sets the modal to reopen.
     * @param {Object} state - The current state.
     * @param {Object} action - The action object.
     * @param {string} action.payload - The modal name to reopen.
     */
    setModalReopen: (state, action) => {
      state.modalReopen = action.payload
    },
    /**
     * Updates a tab or adds a new one.
     * @param {Object} state - The current state.
     * @param {Object} action - The action object.
     * @param {Object} action.payload - The tab object.
     * @param {string} action.payload.id - The tab id.
     * @param {string} action.payload.name - The tab name.
     */
    updateTab: (state, action) => {
      if (!state.tabs.some(tab => tab.id === action.payload.id)) {
        state.tabs = [...state.tabs, action.payload];
      } else {
        const i = state.tabs.findIndex(tab => tab.id === action.payload.id);
        if (i > -1) state.tabs[i].name = action.payload.name;
      };
    },
    /**
     * Deletes a tab.
     * @param {Object} state - The current state.
     * @param {Object} action - The action object.
     * @param {string} action.payload - The id of the tab to delete.
     */
    deleteTab: (state, action) => {
      state.tabs = state.tabs.filter(tab => tab.id !== action.payload)
    },
    /**
     * Sets the active tab and updates related state.
     * @param {Object} state - The current state.
     * @param {Object} action - The action object.
     * @param {string} action.payload - The id of the tab to set active.
     */
    setActiveTab: (state, action) => {
      state.activeTab = action.payload;
      if (toolIndex[state.tool].constants.tool.containerName) {
        state.showing = (state.tabs.at(0).id === action.payload ? 'container' : 'area');
        state.showTable = (state.tabs.at(0).id === action.payload ? 'summary' : state.showTable);
      } else {
        // fixme these should probably be defined when we define the tool
        state.showing = 'area'
        state.zoom = true
      }
      state.action = 'measure';

    },

    /**
     * Sets the current view and updates related state.
     * @param {Object} state - The current state.
     * @param {Object} action - The action object.
     * @param {string} action.payload - The view to set as current.
     * 
     * @description
     * This reducer updates the current view and performs the following actions:
     * 1. Updates the current device based on the new view:
     *    - If the new view is in the devices list, it becomes the current device.
     *    - If the new view is in the hideDevices list, the current device is set to null.
     *    - Otherwise, the current device remains unchanged.
     * 2. Sets the current view to the new view.
     * 3. Updates the showMap state based on the current device:
     *    - If there's a current device, sets showMap to the default plot for that device.
     *    - If there's no current device, sets showMap to null.
     * 4. Sets the action state to 'measure'.
     * 
     * @note
     * The behavior for tools with single or multiple devices may need to be adjusted.
     * Future implementation might need to consider which views should reset the device for multi-device tools.
     */   
     // fixme: the list below is hard coded and prone to errors in the future. Checking for currentView should be removed. See DT-1345
    setCurrentView: (state, action) => {
      const { devices = [], hideDevices = [] } = toolIndex[state.tool].constants;
      const currentDevice = devices.includes(action.payload) ? action.payload : hideDevices.includes(action.payload) ? null : state.currentDevice;
      state.currentDevice = currentDevice; 
      state.currentView = action.payload;
      state.showMap = currentDevice ? devicePlots[currentDevice].default : null;
      state.action = 'measure';
    },
    /**
     * Toggles or sets the zoom state.
     * @param {Object} state - The current state.
     * @param {Object} action - The action object.
     * @param {boolean} [action.payload] - The zoom state to set. If not provided, toggles the current state.
     */
    setZoom: (state, action) => {
      state.zoom = (action.payload === undefined ? !state.zoom : action.payload)
    },
    /**
     * Sets the modal forward flag.
     * @param {Object} state - The current state.
     * @param {Object} action - The action object.
     * @param {boolean} action.payload - The modal forward flag.
     */
    modalForward: (state, action) => {
      state.modalForward = action.payload
    },
    /**
     * Sets the allow add area and add another area flags.
     * @param {Object} state - The current state.
     * @param {Object} action - The action object.
     * @param {boolean} action.payload - The flag value to set.
     */
    setAllowAddArea: (state, action) => {
      state.allowAddArea = action.payload;
      state.addAnotherArea = action.payload;
    },
    /**
     * Toggles the add another area flag.
     * @param {Object} state - The current state.
     */
    setAddAnotherArea: (state, action) => {
      state.addAnotherArea = !state.addAnotherArea
    },
    /**
     * Sets the exit to room flag.
     * @param {Object} state - The current state.
     * @param {Object} action - The action object.
     * @param {boolean} action.payload - The exit to room flag.
     */
    setExitToRoom: (state, action) => {
      state.exitToRoom = action.payload
    },
    /**
     * Sets the SVG bounding box.
     * @param {Object} state - The current state.
     * @param {Object} action - The action object.
     * @param {Object} action.payload - The SVG bounding box object.
     */
    setSvgBoundingBox: (state, action) => {
      state.svgBoundingBox = action.payload
    },
    /**
     * Sets the scale.
     * @param {Object} state - The current state.
     * @param {Object} action - The action object.
     * @param {number} action.payload - The scale value.
     */
    setScale: (state, action) => {
      state.scale = action.payload
    },
    /**
     * Sets the bounding box.
     * @param {Object} state - The current state.
     * @param {Object} action - The action object.
     * @param {Object} action.payload - The bounding box object.
     */
    setBoundingBox: (state, action) => {
      state.boundingBox = action.payload
    },
    /**
     * Sets the overlapping state.
     * @param {Object} state - The current state.
     * @param {Object} action - The action object.
     * @param {Object} action.payload - The overlapping state object.
     */
    setOverlaping: (state, action) => {
      state.overlaping = action.payload
    },
    /**
     * Sets the show map state.
     * @param {Object} state - The current state.
     * @param {Object} action - The action object.
     * @param {string} action.payload - The show map state.
     */
    setShowMap: (state, action) => {
      state.showMap = action.payload
    },
    /**
     * Sets the show table state.
     * @param {Object} state - The current state.
     * @param {Object} action - The action object.
     * @param {string} action.payload - The show table state.
     */
    setShowTable: (state, action) => {
      state.showTable = action.payload
    },
    /**
     * Sets the action state.
     * @param {Object} state - The current state.
     * @param {Object} action - The action object.
     * @param {string} action.payload - The action state.
     */
    setAction: (state, action) => {
      state.action = action.payload //?? 'measure'
    },
    /**
     * Sets the bubble content flag.
     * @param {Object} state - The current state.
     * @param {Object} action - The action object.
     * @param {boolean} action.payload - The bubble content flag.
     */
    setBubbleContent: (state, action) => {
      state.bubbleContent = action.payload
    },
    /**
     * Sets the modal response.
     * @param {Object} state - The current state.
     * @param {Object} action - The action object.
     * @param {*} action.payload - The modal response.
     */
    setModalResponse: (state, action) => {
      state.modalResponse = action.payload
    },
    /**
     * Sets or clears an error.
     * @param {Object} state - The current state.
     * @param {Object} action - The action object.
     * @param {Object} action.payload - The error object.
     * @param {string} action.payload.code - The error code.
     * @param {string} [action.payload.msg] - The error message.
     * @param {string[]} [action.payload.params=[]] - The error parameters.
     * @param {boolean} [action.payload.reset=false] - Whether to reset the error.
     */
    setError: (state, action) => {
      const { code, msg = null, params = [], reset = false } = action.payload;
      if (msg) {
        state.error[code] = {
          code: code, // unused but preven drawing?
          params: params, // Parameter to highlight
          msg: msg,
          reset: reset // clear the error when closing the dialog
        }
      } else {
        delete state.error[code]
      }
      const errorCount = keys(state.error).length
      state.errorState = errorCount > 0;
      state.lastError = errorCount > 0 ? code : '';
      if (errorCount === 0) state.modals = state.modals.filter(mod => mod.modalName !== 'warning')
    },
    /**
     * Sets or clears info messages.
     * @param {Object} state - The current state.
     * @param {Object} action - The action object.
     * @param {Object} [action.payload] - The info object.
     * @param {string} [action.payload.code] - The info code.
     * @param {string} [action.payload.msg] - The info message.
     */
    setInfo: (state, action) => {
      const { code = null, msg = null } = action.payload || {};
      if (msg) {
        state.info[code] = {
          code: code,
          msg: msg,
        }
      } else if (code) {
        delete state.info[code]
      } else {
        state.info = {}
      }
    },
    /**
     * Closes warning and resets errors.
     * @param {Object} state - The current state.
     */
    closeWarning: (state, action) => {
      // Reset self resetting errors 
      const keyToDelete = keys(state.error).find(er => state.error[er].reset);
      if (keyToDelete) {
        delete state.error[keyToDelete];
        state.action = 'measure';
      };
      const errorCount = keys(state.error).length
      state.errorState = errorCount > 0;
      if (errorCount === 0) state.modals = state.modals.filter(mod => mod.modalName !== 'warning')
    },
    /**
     * Sets initial project data and updates related state.
     * @param {Object} state - The current state.
     * @param {Object} action - The action object.
     * @param {Object} action.payload - The initial project data.
     */
    setInitProjectData: (state, action) => {
      keys(state).forEach(key => {
        if (key in action.payload) state[key] = action.payload[key];
      });
      const { areas } = action.payload;
      const areaCount = areas.length;
      state.addAnotherArea = areaCount < toolIndex[state.tool].constants.tool.maximumAreas;
      state.allowAddArea = false;
      state.currentView = toolIndex[state.tool].constants.tool.landingMenu;
      state.currentDevice = toolIndex[state.tool].constants.tool.landingMenu
      state.exitToRoom = true;
      state.isLatest = action.payload.latestrevision;
    },
    /**
     * Sets the first-time user flag.
     * @param {Object} state - The current state.
     * @param {Object} action - The action object.
     * @param {boolean} action.payload - The first-time user flag.
     */
    setIsFirstTimeUser: (state, action) => {
      state.isFirstTimeUser = action.payload
    }
  },

  extraReducers: (builder) => {
    builder
      .addCase(containerReducers.setName.type, (state, action) => {
        action = { ...action, 'payload': { ...action.payload, 'id': state.activeTab } };
        uiSlice.caseReducers.updateTab(state, action);
      })

      .addCase(containerReducers.addArea.type, (state, action) => {
        action = { ...action, 'payload': false };
        uiSlice.caseReducers.setZoom(state, action);
      })

      // note: this is temporary until release 
      .addCase(containerReducers.setSpeakerLayout.type, (state, action) => {
        state.apiVersion = action.payload.pop().ApiVersion
        state.showMap = 'directSPL'
      })

      .addCase(containerReducers.setProjectData.type, (state, action) => {
        values(action.payload.areas).forEach(area => {
          const copyAction = { ...action, payload: { id: area.id, name: area.name } };
          uiSlice.caseReducers.updateTab(state, copyAction);
        });
        uiSlice.caseReducers.setInitProjectData(state, action);
      })
  },


});

export const uiReducers = { ...uiSlice.actions }
export default uiSlice.reducer;

// UI Selectors
/**
 * Selector to check if the units are in meters.
 * @param {Object} state - The Redux state.
 * @returns {boolean} True if units are in meters, false otherwise.
 */
export const getIsMeter = state => state.ui.units === 'meters'
/**
 * Selector to get the bounding box.
 * @param {Object} state - The Redux state.
 * @returns {Object} The bounding box object.
 */
export const getBoundingBox = state => state.ui.boundingBox;
/**
 * Selector to get the scale.
 * @param {Object} state - The Redux state.
 * @returns {number} The scale value.
 */
export const getScale = state => state.ui.scale;
/**
 * Selector to get the SVG bounding box.
 * @param {Object} state - The Redux state.
 * @returns {Object} The SVG bounding box object.
 */
export const getSVGBoundingBox = state => state.ui.svgBoundingBox;
/**
 * Selector to get the zoom state.
 * @param {Object} state - The Redux state.
 * @returns {boolean} The zoom state.
 */
export const getZoom = state => state.ui.zoom;
/**
 * Selector to get the error state.
 * @param {Object} state - The Redux state.
 * @returns {boolean} The error state.
 */
export const getErrorState = state => state.ui.errorState;
/**
 * Selector to get the reload state.
 * @param {Object} state - The Redux state.
 * @returns {boolean} The reload state.
 */
export const getReload = state => state.ui.reload;

/**
 * Selects the current view from the UI state.
 * @function
 * @param {Object} state - The Redux state.
 * @returns {string} The current view identifier.
 */
export const getCurrentView = state => state.ui.currentView;

/**
 * Selects the current device from the UI state.
 * @function
 * @param {Object} state - The Redux state.
 * @returns {string} The current device identifier.
 */
export const getCurrentDevice = state => state.ui.currentDevice;


/**
 * Selector to get the drawing box properties.
 * @param {Object} state - The Redux state.
 * @returns {Object} An object containing drawing box properties.
 */
export const getDrawingBox = createSelector(getBoundingBox, getScale, getSVGBoundingBox, getZoom,
  state => toolIndex[state.ui.tool].constants.iconMultiplier,
  (bbox, scale, svgbox, zoom, iconAdj) => ({
    bbox: bbox,
    scale: scale,
    svgbox: svgbox,
    zoom: zoom,
    iconAdj: iconAdj
  })
);

/**
 * Selector to get the color gradient based on plot properties.
 * @param {Object} state - The Redux state.
 * @returns {Object} The color gradient object.
 */
export const getColorGradient = createSelector(
  state => getPlotProps(state), 
  (props) => {
  const { plotType: type, ...properties } = props;
  if (type) return gradients[type](properties)
});
/**
 * Cached selector to get a UI property.
 * @param {Object} state - The Redux state.
 * @param {string} property - The property to retrieve.
 * @returns {*} The value of the requested UI property.
 */
export const getUIProperty = createCachedSelector(
  state => state.ui,
  (state, property) => property,
  (uiState, property) => uiState?.[getStoreProp(property)]
)(
  (state, property) => property
);
/**
 * Cached selector to check if a specific error exists.
 * @param {Object} state - The Redux state.
 * @param {Object} args - The arguments object.
 * @param {string} args.errorCode - The error code to check.
 * @param {string} [args.name] - The optional name to check in error params.
 * @returns {boolean} True if the error exists, false otherwise.
 */
export const isInError = createCachedSelector(
  state => values(state.ui.error),
  (state, args) => pick(args, ['errorCode', 'name']),
  (errors, args) => {
    const { errorCode, name = null } = args;
    return errors.some(error => error.code === errorCode && (name ? error.params.includes(name) : true));
  }
)(
  (state, args) => {
    const { errorCode = '0', name = '0' } = args
    return `${errorCode}:${name}`
  },
);

/**
 * Selector to determine if the map should be shown.
 * @function
 * @param {Object} state - The Redux state.
 * @returns {boolean} True if the map should be shown, false otherwise.
 * 
 * @description
 * This selector combines multiple pieces of state to determine if the map should be displayed.
 * It returns true if all the following conditions are met:
 * 1. There is no error state.
 * 2. The current view is one of: 'acoustics', 'designpriority', 'speakers', or 'microphones'.
 * 3. A current device is selected.
 * 4. The application is not in a modal state (exitToRoom is true).
 * 
 * @example
 * const showMap = useSelector(getShowMap);
 * if (showMap) {
 *   // Render map component
 * }
 */
// fixme: the list below is hard coded and prone to errors in the future. Checking for currentView should be removed. See DT-1345
export const getShowMap = createSelector(
  getErrorState,
  getCurrentView,
  getCurrentDevice,
  state => state.ui.exitToRoom,
  (error, currentView, currentDevice, noModal) => {
    return !error && ['acoustics', 'designpriority', 'speakers', 'microphones'].includes(currentView) && currentDevice && noModal
  }
);