import ReducersIndex from 'reduxModules/ducks';
import { getRecalculate, getRemap, getAvailableArea } from 'reduxModules/ducks/container';
import toolIndex from "toolConstants";
import { isEqual, isEmpty, omit, pick, keys } from 'lodash';



const callerMiddleware = ({ dispatch, getState }) => (next) => (action) => {
  const currentStore = getState();
  next(action);
  let store = getState();

  // marker maybe I need to filter the action by only those actios that would trigger a recalculate 
  // fixme memoize these 
  // Get the parameters
  const tool = store.container.currentTool;
  const containerParams = toolIndex[tool].constants.containerParams;
  const areaParams = toolIndex[tool].constants.areaParams;

  // destructure current state
  const { areas: currentAreas, ...currentContainer } = pick(currentStore.container, containerParams);
  const currentAreasKeys = keys(currentAreas);

  // destructure updated state 
  const { areas: updatedAreas, ...updatedContainer } = pick(store.container, containerParams);

  const containerChanged = !isEqual(currentContainer, updatedContainer);

  if (containerChanged) {
    dispatch(ReducersIndex.container.setRecalculate({ flag: true }))
    console.log('container changed');
  } else {
    currentAreasKeys.forEach((key, index) => {
      const areaExists = key in updatedAreas;
      const areaPre = pick(currentAreas[key], areaParams);
      const areaPost = areaExists ? pick(updatedAreas[key], areaParams) : {};
      const areaChanged = !isEqual(areaPre, areaPost);
      if (areaExists && areaChanged) {
        console.log("area changed: ", key);
        dispatch(ReducersIndex.container.setRecalculate({ area: key, flag: true }));
      }
    })
  };

  const viewFilter = !['floorplan', 'roof', 'areausage'].includes(store.ui.currentView);

  // marker 
  store = getState();
  const changed = getRecalculate(store) || getRemap(store);

  const ready = !store.ui.isModalOpen && viewFilter && changed;
  if (ready && store.ui.errorState) {
    // stop
    return
  }

  const debounceTime = (ready && action.type.includes('deleteSpeaker')) ? 2000 : 500

  // fixme: this is only for speakers in wd
  retriever(dispatch, getState, ready, debounceTime); //, { ...updatedContainer });
};


// Marker retriever is pretty much fetch 
let saveTimer;

const retriever = (dispatch, getState, ready, debounceTime = 500) => {
  const store = getState();
  const tool = store.container.currentTool;
  if (saveTimer) clearTimeout(saveTimer);

  saveTimer = setTimeout(() => {
    const getSpeakerLayout = async (payload, remap = false) => {
      //todo: address below needs to be in .env
      // const url = `https://acousticfunctiontest20230314182401.azurewebsites.net/api/${remap ? 'GetMapOfSpeakerLayout' : 'GetSpeakerLayout'}`; // develop version
      const url = `https://wdakustxengine-20240906v1p2.azurewebsites.net/api/${remap ? 'GetMapOfSpeakerLayout' : 'GetSpeakerLayout'}` ; // live version 

      const response = await fetch(url, {
        "method": "POST",
        "headers": {
          "content-type": "text/plain",
          "accept": "*/*"
        },
        "body": JSON.stringify(payload),
      });
      if (response.status === 200) {
        dispatch(ReducersIndex.uiReducers.closeModal('calculating'));
        const body = await response.text();
        const data = JSON.parse(body)
        if ('errornumber' in data) {
          dispatch(ReducersIndex.container.setRecalculate({ flag: false }));
          alert(data.errortext);
        } else {
          dispatch(ReducersIndex.container.setSpeakerLayout(JSON.parse(body)));
          dispatch(ReducersIndex.uiReducers.setAllowAddArea(!isEmpty(getAvailableArea(store.container))));
        };
      } else {
        // error message 
        console.log("API Error: ", response.text())
      }
    };

    // fixme
    if (ready && store.ui.tool === 'warehouseDesigner') {

      dispatch(ReducersIndex.uiReducers.openModal('calculating'));
      const remap = getRemap(store);
      const { container: containerFilterKeys, area: areaFilter } = toolIndex[tool].constants.callFilter;
      const areaFilterKeys = areaFilter(remap);
      const { areas, ...container } = omit(store.container, containerFilterKeys);

      const filtered = keys(areas).reduce((acc, key) => {
        acc[key] = omit(areas[key], areaFilterKeys);
        return acc
      }, {});
      // eslint-disable-next-line
      const payload = { ...container, ['areas']: filtered };
      getSpeakerLayout(payload, remap);
    };
  }, debounceTime, dispatch);
};



export default callerMiddleware;