import { useRef, useLayoutEffect, useState, useContext } from "react";
import styled, { ThemeProvider } from "styled-components";
import { useSelector } from "react-redux";
import ToolContext from 'components/services/ToolContext';
import { ComponentsIndex } from "globalConstants/ComponentIndex";
import { modalThemes } from "globalConstants/Themes";
import { getConditionResult } from "reduxModules/ducks/container";

const NavSection = (props) => {
  const sectionName = props.item //.replace('_', '');
  const active = useSelector(state => state.ui.currentView) === sectionName.replace('_', '');
  const showing = useSelector(state => state.ui.showing)
  const lines = useContext(ToolContext).menus[sectionName][showing];

  const [height, setHeight] = useState(0);

  return (
    <NavSection.Wrapper visible={active} height={height} lines={lines}>
      <ThemeProvider theme={modalThemes['default']}>
        <SectionBody setHeight={setHeight} lines={lines} />
      </ThemeProvider>
    </NavSection.Wrapper>
  )
};

NavSection.Wrapper = styled.div.attrs(props => ({
  className: `ms-3 mb-${props.visible ? 2 : 0} `
}))`
  border-bottom: ${props => `1px solid ${props.visible ? 'var(--app-red)' : '#fff'}`};
  overflow: hidden;
  max-height: ${props => props.visible ? 'auto' : '0px'};
  transition: max-height 1s ease-out;
  display: flex
`;
// `${props.height}px`

// Note: section body might be almost the same as modal if so, move to organism along with the lines below
const SectionBody = (props) => {
  const setHeight = props.setHeight
  const childRef = useRef();

  useLayoutEffect(() => {
    const { height } = childRef.current.getBoundingClientRect();
    setHeight(height);
  });

  return (
    <div ref={childRef} className='vstack pb-2 fs-6'>
      {props.lines ? props.lines.map((line, index) => {
        const lineProps = {
          ...line,
          dispatcher: line.dispatcher || props.dispatcher,
        };
        return <Line key={`${line.name}-${index}`} {...lineProps} />
      }) : <div>This section has not been create yet</div>
      
      }
    </div>
  )
};



// Note: if line and linewrapper are the same as modal - mode the modals out into their own organisms
const Line = (props) => {
  const visible = useSelector(state => getConditionResult(state, {...props.condition}));
  const Element = ComponentsIndex[props.element];
  return (
    // <div className='align-items-start'>
    <Line.LineWrapper animate={true} visible={visible}>
    <Element {...props} isNav dispatcher={props.dispatcher} value={props.value} />
    </Line.LineWrapper>
    // </div>
  )
};

Line.LineWrapper = styled.div.attrs(props => ({
  className: `menuLine mb-${props.visible ? 2 : 0}`
}))`
  overflow: hidden;
  max-height: ${props => props.visible ? 'fit-content' : '0px'};
  transition: ${props => props.animate ? 'max-height .6s ease-out' : 'none'};
`;



export default NavSection;

