import { useStore } from "react-redux";
import { useLocation } from 'react-router';
import { useContext, useCallback } from "react";
import ToolContext from "components/services/ToolContext";
import { keys, pick, toPairs } from "lodash";

const Payload = (keyMap = null) => {
  const context = useContext(ToolContext);
  const { fullName: tool, ver: toolversion } = context.constants.tool;

  const { uiKeys = [], containerKeys = [], areaKeys = [] } = context.constants[keyMap] || {};
  const { search } = useLocation();
  const { savekey, ver } = Object.fromEntries(new URLSearchParams(search));

  const store = useStore();

  const payload = useCallback(() => {
    const state = store.getState();
    return (
      {
        tool: tool.toLowerCase().replace(/ /g, ''),
        toolversion: toolversion,
        date: new Date(),
        locale: Intl.DateTimeFormat().resolvedOptions().locale,
        savekey: savekey,
        revision: ver,

        ...pick(state.ui, uiKeys),

        ...pick(state.container, containerKeys),

        areaList: keys(state.container.areas),

        areas: areaKeys.length > 0 ? {
          ...toPairs(state.container.areas).reduce((acc, [key, value]) => {
            if (value) acc[key] = { ...pick(value, areaKeys), speakerCount: value.speakers.length }
            return acc
          }, {})
        } : {},
      }
    )
  }
    , [uiKeys, containerKeys, areaKeys]);

  return payload();
}

export default Payload;